<!-- cookie-policy -->
<div class="cookie-policy-container">
    <h1>Cookie Policy</h1>
  
    <mat-tab-group>
      <mat-tab label="Consent">
        <br />
        <p>
          <span class="bold-text">Website uses cookies</span>
          We use cookies to personalise content and ads, to provide social media features, and to analyse our traffic. We also share information about your use of our site with our social media, advertising, and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.
        </p>
      </mat-tab>
      <mat-tab label="Details">
        <br />
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="panel-header">
            <mat-panel-title class="bold-text">Necessary</mat-panel-title>
                <div class="styled-checkbox">
                    <input type="checkbox" id="necessary-checkbox" />
                    <label for="necessary-checkbox">&nbsp;</label>
              </div>
            </div>
          </mat-expansion-panel-header>
          <span>
            Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.
          </span>
        </mat-expansion-panel>
      </mat-tab>
      <mat-tab label="About">
        <br />
        <p>
          Cookies are small text files that can be used by websites to make a user's experience more efficient.
          <br /><br />
          Learn more about who we are, how you can contact us and how we process personal data in ourPrivacy Policy.
          <br /><br />
          The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission.
          <br /><br />
          This site uses different types of cookies. Some cookies are placed by third-party services that appear on our pages.
        </p>
      </mat-tab>
    </mat-tab-group>
  
    <div class="actions-row">
      <button mat-button class="btn btn-deny" (click)="close()">Deny</button>
      <button mat-button class="btn btn-customize" (click)="customizePolicy()">Customize</button>
      <button mat-button class="btn btn-allow" (click)="acceptPolicy()">Allow All</button>
    </div>
  </div>
  