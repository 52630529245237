import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as $ from 'jquery';
import { withLatestFrom } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, CdkDragEnd } from '@angular/cdk/drag-drop';
import { PubNubAngular } from 'pubnub-angular2';
import { Toast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ChildToParentService } from 'src/app/child-to-parent.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  route;
  drName;
  dr_name;
  dr_degree;
  hos_name;
  dmail;
  doc_id;
  token;
  urlPoints;
  drinOutPopup = false;
  docstatus =false;
  docbool = false;
  walletdetails = "0"
  floatingbalance = "0";
  imageGetUrl;
  drImage;
  isShowfloat = true;
  transform_x
  transform_y
  positionX
  positionY

  pubnub: PubNubAngular;
  last_user ;
  msg;
  transformVitalScore_x
  transformVitalScore_y


  onFloatChangedSub: Subscription;
  getVersionArray;
  getVersionArray1 : any = [];
  imageUploadUrl;
  vhnimageUploadUrl;
  versionData: any;
  count1
  count2

  constructor(private router:Router,private appService: AppService,private childToParentService: ChildToParentService, pubnub: PubNubAngular,public dialog: MatDialog, private toastr: ToastrService) {
    this.urlPoints = AppConfig.settings.EndPoints;
    this.pubnub = pubnub;
    this.imageGetUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    
    this.onFloatChangedSub = this.childToParentService.onFloatChanged$.subscribe($event => {
    //  this.doSomething($event);
  //  alert($event)
   this.isShowfloat =$event
    });

   }

   handleRouteChange = () => {
     this.route = this.router.url
  };

  
  ngOnInit() {
    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.drName = getauthdetails.doctor_name;
    localStorage.setItem('dr_name', getauthdetails.doctor_name);
    this.dr_name = getauthdetails.doctor_name;
    this.dr_degree = getauthdetails.doctor_degree;
    this.hos_name = getauthdetails.hospital_name;
    this.dmail =  getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;
    this.drImage = this.imageGetUrl+getauthdetails.doctor_image;

    this.transform_x=sessionStorage.getItem('dragPositions_x')
    this.transform_y=sessionStorage.getItem('dragPositions_y')
    
    var x=sessionStorage.getItem('dragPositions_x')
    var y=sessionStorage.getItem('dragPositions_y')


    if(x&&y)
    {

  

    }

 
    this.homeDefault_status();
    this.getDrWallet();
 
    sessionStorage.setItem('lastuser','');
    this.pubnub1();
    sessionStorage.setItem('version','v2.0.48')
    this.transformVitalScore_x=sessionStorage.getItem('dragPositionsVital_x')
    this.transformVitalScore_y=sessionStorage.getItem('dragPositionsVital_y')
    this.imageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.vhnimageUploadUrl =  environment.HOST+'/helyxonapi/cloud/image/upload/';
    this.versionCur()

    var params = {
      account_email: this.dmail,
      doctor_id :  this.doc_id
    }

    this.appService.getData(this.urlPoints.patientwaitingcount, params, this.token).subscribe((response: any) => {
      console.log(response)
      this.count1 = response.data.in_person
      this.count2 = response.data.tele_consultation

    });
  }

  versionCur(){
    this.versionData = sessionStorage.getItem('version')
    
  }
  ngAfterViewChecked(){
    
  let drName = localStorage.getItem('dr_name');
  let getsecname = '';
  
  let fname = drName.split(' ')[0];
  let getfname = fname.charAt(0);
  
  let secName = drName.split(' ')[1];
  
if(secName != undefined){
    getsecname = secName.charAt(0);
}
this.drName = getfname+getsecname;
}

toggleStatus(){
  this.docbool = !this.docbool;
}

onActivate(componentReference) {

}

homeDefault_status(){
  var params = {
    account_email: this.dmail,
    doctor_id: this.doc_id
  }
  this.appService.getData(this.urlPoints.getdoctorinoutstatuslog, params, this.token).subscribe((response: any) => {
    if(response.details[0].status == 1){
      this.docstatus = true;
    } else if(response.details[0].status == 0){
      this.docstatus = false;
    }
  });
}

flottoggle(){
 this.isShowfloat = !this.isShowfloat;
 alert(this.isShowfloat)
}



dragEnd($event: CdkDragEnd) {
 

 

  const { offsetLeft, offsetTop } = $event.source.element.nativeElement;
  const { x, y } = $event.distance;
  this.positionX = offsetLeft + x;
  this.positionY = offsetTop + y;

  sessionStorage.setItem('dragPositions_x',this.positionX)
  sessionStorage.setItem('dragPositions_y',this.positionY)

}



getDrWallet(){
  var params = {
    account_email: this.dmail,
    doctor_id: this.doc_id
  }
  this.appService.getData(this.urlPoints.getdoctorwallet, params, this.token).subscribe((response: any) => {
    if (response.status == 1) {
      if(response.data.wallet_balance.length>0){
       this.walletdetails = response.data.wallet_balance[0].wallet_balance;
      }else {
       this.walletdetails = "0"
      }
      if(response.data.floating_balance){
       this.floatingbalance = response.data.floating_balance
      }else {
       this.floatingbalance = "0"
      }
   } 
  });
}

doctor_status(changeStatus){
  //alert(changeStatus);
  if(changeStatus == true){
    var docchangestatus = 1;
  }else{
    var docchangestatus = 0;
  }
  var data = {
    "status" : docchangestatus,
    "account_email": this.dmail,
    "doctor_id": this.doc_id
}
  this.appService.postData(this.urlPoints.createdoctorinoutstatus, data, this.token).subscribe((response: any) => {
  });
}

  notifyVitalCriticalMsg(msg) {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog_vitalCriticalMsgPopup, {
      height: '380px',
      width: '500px',
      data: msg,
      disableClose: true

      
    });
   
     
 
  }



  pubnub1() {

    // alert()
    var account_id = this.dmail
    var channel = account_id.replace('@', '_').replace('.org', '') ;
    console.log(channel);
    
    
    var uuid = account_id
    var doctor_id = this.doc_id


    this.pubnub.init({
      publishKey: 'pub-c-c3cecd91-62bc-4365-b2d1-a6333576f17f',
      subscribeKey: 'sub-c-60c79014-12d7-11e8-b32f-5ea260837941',
      uuid: uuid
    });

    this.pubnub.subscribe({
      channels: [channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'vital_criticalityscore_' + channel + '_' + doctor_id, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id],
      withPresence: true,
      triggerEvents: ['callback', 'message', 'presence']
      
    });
    console.log(channel, 'get_banner_' + channel, 'videocall_' + channel + '_' + doctor_id, 'vital_criticalityscore_' + channel + '_' + doctor_id, 'voice_' + uuid + '_' + doctor_id, 'room_' + uuid + '_' + doctor_id);
    

    this.pubnub.addListener({
      message: (msg :any) => {
        if(msg) {
          if(msg.channel != ""){
            var split_Channel_Name = msg.channel.split("_");
            if(split_Channel_Name[0] == 'vital'){
              this.last_user = sessionStorage.getItem('lastuser')
              if(msg.message.mrno != this.last_user) {
                sessionStorage.setItem('lastuser',msg.message.mrno)
                this.notifyVitalCriticalMsg(msg);
              }
            }
            }
          }
        }
    });


  }
}


@Component({
  selector: 'vital-critical',
  templateUrl: 'vital-critical.html',
  styleUrls: ['vital-critical.scss'],

})
export class  DialogOverviewExampleDialog_vitalCriticalMsgPopup{
  
  constructor(public dialogRef: MatDialogRef<DialogOverviewExampleDialog_vitalCriticalMsgPopup>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  vitalScoraData = [];

  ngOnInit() {
    
  }

  closePopup() :void {
    this.dialogRef.close();
  }
}




