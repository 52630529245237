import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  SystemJsNgModuleLoader,
  APP_INITIALIZER,
} from '@angular/core';import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule} from '@angular/material/icon';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent,DialogOverviewExampleDialog_vitalCriticalMsgPopup } from './dashboard/dashboard.component';
import { AppointmentComponent, DialogOverviewExampleDialog_printList } from './appointment/appointment.component';
import { PatientsComponent } from './patients/patients.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { ObstgynprescrComponent } from './obstGynprescription/obstGynpres.component';
import { CashreceiptComponent } from './cashReceipt/cashreceipt.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { SettingsComponent } from './settings/settings.component';
import { AppConfig } from './app.config';
import { ReportsComponent } from './reports/reportDetails.component';
import { ChatComponent } from './chat/chat.component';
import { HttpClientModule } from '@angular/common/http';
import { initializeApp, constantsApp } from './app.initializer';
import { HashLocationStrategy, LocationStrategy, CommonModule } from '@angular/common';
import { PubNubAngular } from 'pubnub-angular2';
import { VedhprescriptionComponent } from './vedhaprescription/vedhaprescription.component';
import { DialogOverviewExampleDialog_bottomwindow, DialogOverviewExampleDialog_invoice, DialogOverviewExampleDialog_labreports, DialogOverviewExampleDialog_notifyvideocall, 
  DialogOverviewExampleDialog_obstGynec,DialogOverviewExampleDialog_consultHistory,DialogOverviewPrintTemplate, Dialog_attachment, Dialog_attachment2, Dialog_attachment_edit, Dialog_follow_book, 
   DialogOverviewExampleDialog_questionareetHistory,DialogOverviewExampleDialog_paediatricsMilestone,DialogOverviewselectTemplate,
  DialogOverviewExampleDialog_vaccinationwindow, DialogOverviewExampleDialog_printvaccine, drugType,DialogOverviewExampleDialog_medicaltHistory,DialogOverviewExampleDialog_clinicaltHistory, DialogOverviewExampleDialog_openattachment, paymentsheet, growth, DialogOverviewExampleDialog_postvitals,  DialogOverviewExampleDialog_pending, DialogOverviewExampleDialog_paediatricsMotherantenatal,  DialogOverviewExampleDialog_deffer, DialogOverviewExampleDialogaddnotgiven, DialogOverviewExampleDialog_paediatricBabyHistory, DialogOverviewExampleDialog_familyhistory, DialogOverviewExampleDialog_growthdetail, DialogOverviewExampleDialog_profileSocialHistory, DialogOverviewExampleDialog_icons2, DialogOverviewExampleDialog_speech, DialogOverviewExampleDialog_tempchoose1, DialogOverviewExampleDialogstatusedit, Dialog_chiefComplaints, Dialog_examination, Dialog_diagnosis, Dialog_investigation, Dialog_prescription, Dialog_advice, Dialog_followup, Dialog_hpi,Med_description, Dialog_pmh, Dialog_social, Dialog_current, DailogOverviewExampleDailog_vitalHistory, DialogOverviewExampleDialogvitalGlucometer, Dialog_addpacs, Dialog_pac_attachments, DialogOverviewExampleDialog_social, DialogOverviewExampleDialog_family, DialogOverviewExampleDialog_threshold } from './chief-complaint/chief-complaint.component';
 

import { ChiefComplaintComponent, DialogOverviewExampleDialog, DialogOverviewExampleDialogListTemp, DialogOverviewExampleDialogmilestonepicture, DialogOverviewExampleDialogSlider,DialogOverviewExampleDialogSlider2,DialogOverviewExampleDialogSlider3, DialogOverviewExampleDialog_cartdrugs, DialogOverviewExampleDialog_cartitems, DialogOverviewExampleDialog_master,Dialog_frequency, Dialog_getName, Dialog_upload } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialog_labtest } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialog_vaccination } from './chief-complaint/chief-complaint.component';
import { Dialog_template } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialog_payment,DialogOverviewExampleDialog_tempchoose } from './chief-complaint/chief-complaint.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input'
import {MatTooltipModule} from '@angular/material/tooltip'
import {MatAutocompleteModule} from '@angular/material/autocomplete'

import {MatNativeDateModule} from '@angular/material/core'
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DialogOverviewExampleDialogpara } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogpara2 } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogabortion } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogabortion2 } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogriskhistory } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogaddcurrentpregRecords } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogaddscanreport } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogaddfolliclestudy } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogviewarchivedcpRecords } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogviewarchivedcpRisk } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogviewarchivedcpscan } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogviewarchivedfollicle } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogalertscanfollowup } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialogpopupexit } from './chief-complaint/chief-complaint.component';
import { DialogOverviewExampleDialoglmpmissingpopup } from './chief-complaint/chief-complaint.component';

import { DialogOverviewExampleDialoglclearlmpeddaddDeatils } from './chief-complaint/chief-complaint.component';
import {MatSidenavModule} from '@angular/material/sidenav';

import { Print } from './chief-complaint/chief-complaint.component';
import { DragDropModule} from '@angular/cdk/drag-drop';
import {MatMenuModule} from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { SharedModule } from './shared/shared.module';
import { DialogOverviewExampleDialog_addVaccine, DialogOverviewExampleDialog_allergy, DialogOverviewExampleDialog_chronic, DialogOverviewExampleDialog_chronic2, DialogOverviewExampleDialog_deffer1,Addnewcarenet,Carenet, DialogOverviewExampleDialog_details, DialogOverviewExampleDialog_growthchart, ProfileComponent } from './profile/profile.component';
import { DialogOverviewExampleDialog_vital } from './profile/profile.component';
import { ToastrModule } from 'ngx-toastr';
import {InfiniteScrollModule} from 'ngx-infinite-scroll'
import { AvatarModule } from 'ngx-avatar';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { CountdownModule } from 'ngx-countdown';
import { ActivityDashboardComponent, DialogOverviewExampleDialog_whatsNewPopup, DialogCookiesPolicy} from './Doctordashboard/ActivityDashboard/activitydashboard.component'; 
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DialogOverviewExampleDialogprintvaccine, VaccinationComponent } from './vaccination/vaccination.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatTabsModule} from '@angular/material/tabs';
import { Dialog_CroppedImage, DrprofileComponent } from './drProfile/drProfile.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ActionsComponent, DialogOverviewExampleDialog_labnotes,DialogOverviewExampleDialog_doctornotes } from './actions/actions.component';
import { CircularSliderComponent } from './circular-slider/circular-slider.component';
import { CircularSliderHandleDirective } from './directives/circular-slider-handle.directive';
import {MatSliderModule} from '@angular/material/slider';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { RatingModule } from 'ng-starrating';
import { ChatmsgesComponent, DialogOverviewExampleDialog_chat } from './chat/chatmsges/chatmsges.component';
import { PatientslistComponent,ExampleHeader } from './patients/patientslist/patientslist.component';
import {MatBadgeModule} from '@angular/material/badge'
import { MatPaginatorModule } from '@angular/material/paginator';
import { PaginateComponent } from './paginate/paginate.component';
import { BookappointmentComponent } from './bookappointment/bookappointment.component';
import { ImageCropperModule } from './image-cropper/image-cropper.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DailycollectionComponent } from './reports/dailyCollection/dailycollection.component';
import { DailyrangecollectionComponent } from './reports/dailyrangeCollection/dailyrangecollection.component';
import { WallettransactionComponent } from './reports/walletTransaction/walletTransaction.component';
import { RegistrationreportComponent } from './reports/registration/registration.component';
import { VideocallReportComponent } from './reports/Videocall/videocall.component';
import { FollowupReportComponent } from './reports/followup/followup.component';
import { CovidreportComponent } from './reports/covid/covid.component';
import { VaccinationreportComponent } from './reports/vaccination/vaccination.component';
import { HomevisitreportComponent } from './reports/homevisit/homevisit.component';
import { VhnperformanceComponent } from './reports/vhnPerformance/vhnPerformance.component';
import { BabyhealthComponent } from './reports/babyhealth/babyhealth.component';
import { BabygrowthComponent } from './reports/babygrowth/babygrowth.component';
import { FeedbackReportComponent } from './reports/feedback/feedback.component';
import { MobileaccessComponent } from './reports/mobileaccess/mobileaccess.component';
import { MhusersReportComponent } from './reports/mhusers/mhusers.component';
import { IvrsReportComponent } from './reports/ivrs/ivrs.component';
import { ActivityReportsComponent } from './Doctordashboard/ActivityReports/activityreports.component';
import { HighchartsChartModule } from 'highcharts-angular';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxPrintModule} from 'ngx-print';
import { CombinedChartMaleSmallComponent } from './combined-chart-male-small/combined-chart-male-small.component';
import { WeightForHeightLengthMaleComponent } from './weight-for-height-length-male/weight-for-height-length-male.component';
import { WeightForHeightLengthMaleSmallComponent } from './weight-for-height-length-male-small/weight-for-height-length-male-small.component';
import { WlhcMaleSmallComponent } from './wlhc-male-small/wlhc-male-small.component';
import { PostVitalsComponent } from './post-vitals/post-vitals.component';
import { IapBmiChartMaleSmallComponent } from './iap-bmi-chart-male-small/iap-bmi-chart-male-small.component';
import { WeightForHeightLengthFemaleSmallComponent } from './weight-for-height-length-female-small/weight-for-height-length-female-small.component';
import { WlhcFemaleSmallComponent } from './wlhc-female-small/wlhc-female-small.component';
import { CombinedChartFemaleSmallComponent } from './combined-chart-female-small/combined-chart-female-small.component';
import { IapBmiChartFemaleSmallComponent } from './iap-bmi-chart-female-small/iap-bmi-chart-female-small.component';
import { WeightForHeightLengthMalePrintComponent } from './weight-for-height-length-male-print/weight-for-height-length-male-print.component';
import { WlhcMalePrintComponent } from './wlhc-male-print/wlhc-male-print.component';
import { CombinedChartMalePrintComponent } from './combined-chart-male-print/combined-chart-male-print.component';
import { IapBmiChartMalePrintComponent } from './iap-bmi-chart-male-print/iap-bmi-chart-male-print.component';
import { WeightForHeightLengthFemalePrintComponent } from './weight-for-height-length-female-print/weight-for-height-length-female-print.component';
import { WlhcFemalePrintComponent } from './wlhc-female-print/wlhc-female-print.component';
import { CombinedChartFemalePrintComponent } from './combined-chart-female-print/combined-chart-female-print.component';
import { IapBmiChartFemalePrintComponent } from './iap-bmi-chart-female-print/iap-bmi-chart-female-print.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { WlhcMaleComponent } from './wlhc-male/wlhc-male.component';
import { CombinedChartMaleComponent } from './combined-chart-male/combined-chart-male.component';
import { IapBmiChartMaleComponent } from './iap-bmi-chart-male/iap-bmi-chart-male.component';
import { WeightForHeightLengthFemaleComponent } from './weight-for-height-length-female/weight-for-height-length-female.component';
import { WlhcFemaleComponent } from './wlhc-female/wlhc-female.component';
import { CombinedChartFemaleComponent } from './combined-chart-female/combined-chart-female.component';
import { IapBmiChartFemaleComponent } from './iap-bmi-chart-female/iap-bmi-chart-female.component';
import { VitallComponent } from './vitall/vitall.component';
import { ApptChatmsgesComponent, DialogOverviewExampleDialog_chat1 } from './appt-chatmsges/appt-chatmsges.component';
import { DialogOverviewExampleDialog_full, PastPrescriptionsComponent } from './past-prescriptions/past-prescriptions.component';
import { EngagementComponent, DialogOverviewExampleDialog_engagementmessageing, DialogOverviewExampleDialog_engagementPopup } from './settings/engagement/engagement.component';
import { VersionListComponent } from './dashboard/version-list/version-list.component';
import { CashCollectionComponent } from './cash-collection/cash-collection.component';
import { HomevisitFollowupComponent } from './reports/homevisit-followup/homevisit-followup.component';
import { OrdersreportComponent } from './ordersreport/ordersreport.component';
import { ZXingScannerModule,ZXingScannerComponent } from '@zxing/ngx-scanner';
import { QrscanerComponent } from './qrscaner/qrscaner.component';
import { GynecrepotComponent } from './gynecrepot/gynecrepot.component';
import { BannerComponent } from './settings/banner/banner.component';
import { PatientReportsComponent, DialogOverviewExampleDialogCheckboxoptions } from './reports/patient-reports/patient-reports.component';
import { CookieService } from 'ngx-cookie-service';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AppointmentComponent,
    DrprofileComponent,
    SettingsComponent,
    PatientsComponent,
    PatientslistComponent,
    ReportsComponent,
    ChatComponent,
    ChatmsgesComponent,
    PrintLayoutComponent,
    PrescriptionComponent,
    VedhprescriptionComponent,
    ObstgynprescrComponent,
    CashreceiptComponent,
    ChiefComplaintComponent,
    ProfileComponent,
    PaginateComponent,
    BookappointmentComponent,
    Dialog_CroppedImage,
    DialogOverviewExampleDialog,
    DialogOverviewExampleDialog_labtest,
    DialogOverviewExampleDialog_vaccination,
    DialogOverviewExampleDialogpara,
    DialogOverviewExampleDialogpara2,
    DialogOverviewExampleDialogabortion,
    DialogOverviewExampleDialogabortion2,
    DialogOverviewExampleDialogriskhistory,
    DialogOverviewExampleDialogaddcurrentpregRecords,
    DialogOverviewExampleDialogaddscanreport,
    DialogOverviewExampleDialogaddfolliclestudy,
    DialogOverviewExampleDialogviewarchivedcpRecords,
    DialogOverviewExampleDialogviewarchivedcpRisk,
    DialogOverviewExampleDialogviewarchivedcpscan,
    DialogOverviewExampleDialogviewarchivedfollicle,
    DialogOverviewExampleDialogalertscanfollowup,
    DialogOverviewExampleDialogpopupexit,
    DialogOverviewExampleDialoglmpmissingpopup,
    DialogOverviewExampleDialoglclearlmpeddaddDeatils,
    DialogOverviewExampleDialog_bottomwindow,
    DialogOverviewExampleDialog_obstGynec,
    DialogOverviewExampleDialog_consultHistory,
    DialogOverviewExampleDialog_medicaltHistory,
    DialogOverviewExampleDialog_clinicaltHistory,
    DialogOverviewExampleDialog_paediatricsMilestone,
    DialogOverviewExampleDialog_questionareetHistory,
    DialogOverviewExampleDialog_profileSocialHistory,
    DialogOverviewExampleDialog_familyhistory,
    DialogOverviewExampleDialogprintvaccine,
    DialogOverviewExampleDialog_openattachment,
    DialogOverviewExampleDialog_paediatricsMotherantenatal,
    DialogOverviewExampleDialog_paediatricBabyHistory,
    paymentsheet,
    DialogOverviewExampleDialogmilestonepicture,
    DialogOverviewselectTemplate,
    Print,
    Dialog_template,
    ActivityDashboardComponent,
    ActivityReportsComponent,
    DialogOverviewExampleDialog_payment,
    DialogOverviewExampleDialog_tempchoose,
    VaccinationComponent,
    DialogOverviewExampleDialog,
    Dialog_getName,
    ActionsComponent,
    DialogOverviewExampleDialogListTemp,
    DialogOverviewExampleDialog_allergy ,
    DialogOverviewExampleDialog_vital,
    DialogOverviewExampleDialog_doctornotes,
    DialogOverviewExampleDialog_growthchart,
    DialogOverviewExampleDialog_labnotes,
    CircularSliderComponent,
    CircularSliderHandleDirective,
    DialogOverviewExampleDialogSlider,
    DialogOverviewExampleDialogSlider2,
    DialogOverviewExampleDialogSlider3,
    DialogOverviewExampleDialog_chat,
    DialogOverviewExampleDialog_chat1,
    DialogOverviewExampleDialog_notifyvideocall,
    DialogOverviewExampleDialog_invoice,
    DailycollectionComponent,
    DailyrangecollectionComponent,
    WallettransactionComponent,
    RegistrationreportComponent,
    VideocallReportComponent,
    FollowupReportComponent,
    CovidreportComponent,
    VaccinationreportComponent,
    HomevisitreportComponent,
    VhnperformanceComponent,
    DialogOverviewPrintTemplate,
    BabyhealthComponent,
    BabygrowthComponent,
    FeedbackReportComponent,
    MobileaccessComponent,
    MhusersReportComponent,
    IvrsReportComponent,
    DialogOverviewExampleDialog_master,
    Dialog_frequency,
    Dialog_upload,
    DialogOverviewExampleDialog_cartdrugs,
    DialogOverviewExampleDialog_cartitems,
    DialogOverviewExampleDialog_labreports,
    Dialog_attachment2,
    Dialog_attachment,
    Dialog_attachment_edit,
    Dialog_follow_book,
    drugType,
    DialogOverviewExampleDialog_vaccinationwindow,
    DialogOverviewExampleDialog_printvaccine,
    DialogOverviewExampleDialog_pending,
    DialogOverviewExampleDialog_deffer,
    DialogOverviewExampleDialogaddnotgiven,
    CombinedChartMaleSmallComponent,
    growth,
    WeightForHeightLengthMaleComponent,
    WeightForHeightLengthMaleSmallComponent,
    WlhcMaleSmallComponent,
    PostVitalsComponent,
    DialogOverviewExampleDialog_postvitals,
    IapBmiChartMaleSmallComponent,
    WeightForHeightLengthFemaleSmallComponent,
    WlhcFemaleSmallComponent,
    CombinedChartFemaleSmallComponent,
    IapBmiChartFemaleSmallComponent,
    WeightForHeightLengthMalePrintComponent,
    WlhcMalePrintComponent,
    CombinedChartMalePrintComponent,
    IapBmiChartMalePrintComponent,
    WeightForHeightLengthFemalePrintComponent,
    WlhcFemalePrintComponent,
    CombinedChartFemalePrintComponent,
    IapBmiChartFemalePrintComponent,
    DialogOverviewExampleDialog_growthdetail,
    WlhcMaleComponent,
    CombinedChartMaleComponent,
    IapBmiChartMaleComponent,
    WeightForHeightLengthFemaleComponent,
    WlhcFemaleComponent,
    CombinedChartFemaleComponent,
    IapBmiChartFemaleComponent,
    DialogOverviewExampleDialog_chronic,
    DialogOverviewExampleDialog_chronic2,
    DialogOverviewExampleDialog_icons2,
    VitallComponent,
    DialogOverviewExampleDialog_speech,
    DialogOverviewExampleDialog_tempchoose1,
    DialogOverviewExampleDialogstatusedit,
    DialogOverviewExampleDialog_printList,
    ApptChatmsgesComponent,
    PastPrescriptionsComponent,
    DialogOverviewExampleDialog_full,
    DialogOverviewExampleDialog_details,
    Carenet,
    Addnewcarenet,
    Dialog_chiefComplaints,
    Dialog_examination,
    Dialog_diagnosis,
    Dialog_investigation,
    Dialog_prescription,
    Dialog_advice,
    Dialog_followup,
    Dialog_hpi,
    Med_description,
    Dialog_pmh,
    Dialog_social,
    Dialog_current,
    DialogOverviewExampleDialog_full,
    DailogOverviewExampleDailog_vitalHistory,
    DialogOverviewExampleDialogvitalGlucometer,
    EngagementComponent,
    DialogOverviewExampleDialog_engagementmessageing,
    DialogOverviewExampleDialog_engagementPopup,
    DialogOverviewExampleDialog_vitalCriticalMsgPopup,
    DialogOverviewExampleDialog_whatsNewPopup,
    DialogCookiesPolicy,
    VersionListComponent,
    CashCollectionComponent,
    Dialog_addpacs,
    EngagementComponent,
    DialogOverviewExampleDialog_engagementmessageing,
    DialogOverviewExampleDialog_engagementPopup,
    Dialog_pac_attachments,
    DialogOverviewExampleDialog_deffer1,
    DialogOverviewExampleDialog_addVaccine,
    HomevisitFollowupComponent,
    DialogOverviewExampleDialog_social,
    DialogOverviewExampleDialog_family,
    DialogOverviewExampleDialog_threshold,
    OrdersreportComponent,
    QrscanerComponent,
    GynecrepotComponent,
    BannerComponent,
    PatientReportsComponent,
    DialogOverviewExampleDialogCheckboxoptions,
    ExampleHeader
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserModule,
    CdkAccordionModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatRadioModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    MatMenuModule,
    MatDialogModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatBadgeModule,
    MatTableModule,
    SharedModule,
    ImageCropperModule,
    CountdownModule,
    NgxPrintModule,
    HighchartsChartModule,
    MatSnackBarModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxDaterangepickerMd.forRoot(),
    RatingModule,
    InfiniteScrollModule,
    DragDropModule,
    MatTabsModule,
    MatPaginatorModule,
    AvatarModule,
    AngularFileUploaderModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSliderModule,
    NgxMaterialTimepickerModule,
    MatSidenavModule,
    ZXingScannerModule
  ],
  
  providers: [
    PubNubAngular,
    AppConfig,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: constantsApp,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    // tslint:disable-next-line: deprecation
    SystemJsNgModuleLoader,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
  ],
  exports: [
    CircularSliderComponent,
    CircularSliderHandleDirective,
  ],
  bootstrap: [AppComponent],entryComponents: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
