<div style="display: flex;justify-content: space-between;font-size: 1rem;">
        <p style=color:#0063FF;font-weight:600>Prescription</p>

        <div style="display: flex;">
                        <div  *ngIf="openSearchOption">      
                                <form>
                        <mat-form-field>
                          <mat-label>
                                Search Products                         
                          </mat-label>
                          <input style="margin-bottom: 14px; background-color: lightgray;
                          border-radius: 5px;
                          height: 26px;"name="search_drug" matInput [(ngModel)]="search_drug" (input)="searchDrug($event)" type="text" [matAutocomplete]="auto" (click)="openSearchOptionPanel = !openSearchOptionPanel"/>
                          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchDrug($event)" [panelOpen]="localDrugArr.length > 0 ||openSearchOptionPanel">
                            <mat-option *ngFor="let item of localDrugArr" (mousedown)="onDropdownChange($event)" [value]="item.name__">
                              {{item.name__}} <span class="hide_id" disabled>{{item.id__}}</span>
                            </mat-option>
                            <mat-option *ngIf="searchResult && localDrugArr.length == 0" disabled>
                                Result not found
                              </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                </form>
                </div>

<!-- drop down list -->
                      <!-- <div  *ngIf="dropdownData.length > 0">
                      <mat-form-field> -->
                        <!-- <input matInput placeholder="Search..." [(ngModel)]="searchText" (ngModelChange)="filterOptions()" > -->
                        <!-- <mat-select placeholder="Select Drugs"> -->
                          <!-- <mat-option disabled></mat-option> -->
                          <!-- <mat-option (mousedown)="onDropdownChange($event)" *ngFor="let drugs of localStore" [value]="drugs">{{drugs}}</mat-option> -->
                          <!-- <mat-option (mousedown)="onDropdownChange($event)" *ngFor="let drugs of dropdownData" [value]="drugs.product_name">{{drugs.product_name}} <span class="hide_id" disabled>{{drugs.product_id}}</span></mat-option> -->
                          <!-- <mat-option disabled (mousedown)="loadMoreEvent($event)" class="load_more_txt">Load more</mat-option> -->
                        <!-- </mat-select> -->
                      <!-- </mat-form-field> -->
                <!-- </div> -->
                <div>
                        <div style="display: flex;justify-content: center;">
                                <img class="icon" [class.highlight]="block==1" (click)="setBlock(1)"
                                        src="assets/abc.svg">
                        </div>
                        <p style="font-size: 0.75rem;">Search by text</p>
                </div>

                <div style="margin-left: 00.5rem;">
                        <div style="display: flex;justify-content: center;">
                                <img class="icon" [class.highlight]="block==2" (click)="setBlock(2)"
                                        style="margin-left: 00.5rem;" src="assets/medical-report.svg">
                        </div>
                        <p style="font-size: 0.75rem;">Suggestions</p>
                </div>

                <div style="margin-left: 00.5rem;">
                        <div style="display: flex;justify-content: center;">
                                <img class="icon" src="assets/keyboard.svg" (click)="openKeyboard()">
                        </div>
                        <p style="font-size: 0.75rem;">Keyboard</p>
                </div>
                <div style="margin-left: 00.5rem;">
                        <div style="display: flex;justify-content: center;">
                                <img class="icon" (mouseup)="mouseup()" (mousedown)="mousedown()"
                                        (mouseleave)="mouseup()" [src]="muted?'assets/mute.svg':'assets/unmute.svg'">
                        </div>
                        <p style="font-size: 0.75rem;">Voice</p>
                </div>
                <div style="border: 2px solid #7D7D7D;height: 1.6rem;margin-left: 00.5rem;border-radius:5px">
                        <input style="border: none;outline: none;width: 10rem;" [(ngModel)]="search"
                                (input)="search1($event)" placeholder="Search"> <i style="margin-right: 0.5rem;"
                                class="fa fa-search" aria-hidden="true"></i>

                </div>

                <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary"
                        (click)="save()">Save</button>

                <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;" class="fa fa-close"
                        (click)="close()"></i>

        </div>


</div>

<div *ngIf="showKB" style="display: flex;flex-wrap: wrap;margin-top:0.5rem">
        <p class="alphabet" *ngFor="let alphabet of alphabets" (click)="keyboard(alphabet)">
                {{alphabet}}</p>
        <p class="cc_item alphabet" (click)="clear()">Clear</p>
</div>

<div style="display: flex;justify-content: space-between;margin-top: 0.5rem;">
        <p style="color: #7D7D7D;">Added Prescriptions</p>

        <div>
                <img style="height: 1.5rem;width: 1.5rem;cursor: pointer;margin-right: 0.5rem;" (click)="drugType()"
                        src="assets/settings.svg">

                <img style="height: 1.5rem;width: 1.5rem;cursor: pointer;margin-right: 0.5rem;" (click)="openIcons()"
                        src="assets/awesome-info-circle.svg">

                <img style="height: 1.5rem;
        width: 1.5rem;cursor: pointer;" (click)="new_prescription()" src="assets/add.png">
        </div>


</div>

<div>

        <div [ngStyle]="{'max-height':data.toggle_alignment?'11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;">
                <div #cc_item style="border-radius: 2px ;color: #00AF69 !important;border:1px solid #00AF69 !important;"
                        class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
                        *ngFor="let cc of cc_selected;let indexOfelement=index;let i = index">

                        <span *ngIf="cc.formulation=='Injection'"><img class="pres_icon" src="assets/g_icon1.svg"></span>
                        <span *ngIf="cc.formulation==' Injection'"><img class="pres_icon" src="assets/g_icon1.svg"></span>

                        <span *ngIf="cc.formulation=='Lotion'"><img class="pres_icon" src="assets/g_icon2.svg"></span>
                        <span *ngIf="cc.formulation==' Lotion'"><img class="pres_icon" src="assets/g_icon2.svg"></span>

                        <span *ngIf="cc.formulation=='Ointment'"><img class="pres_icon" src="assets/g_icon3.svg"></span>
                        <span *ngIf="cc.formulation==' Ointment'"><img class="pres_icon" src="assets/g_icon3.svg"></span>

                        <span *ngIf="cc.formulation=='Soap'"><img class="pres_icon" src="assets/g_icon4.svg"></span>
                        <span *ngIf="cc.formulation==' Soap'"><img class="pres_icon" src="assets/g_icon4.svg"></span>

                        <span *ngIf="cc.formulation=='Tablet'"><img class="pres_icon" src="assets/g_icon5.svg"></span>
                        <span *ngIf="cc.formulation==' Tablet'"><img class="pres_icon" src="assets/g_icon5.svg"></span>

                        <span *ngIf="cc.formulation=='Suspension'"><img class="pres_icon" src="assets/g_icon6.svg"></span>
                        <span *ngIf="cc.formulation==' Suspension'"><img class="pres_icon" src="assets/g_icon6.svg"></span>

                        <span *ngIf="cc.formulation=='Cream'"><img class="pres_icon" src="assets/g_icon7.svg"></span>
                        <span *ngIf="cc.formulation==' Cream'"><img class="pres_icon" src="assets/g_icon7.svg"></span>

                        <span *ngIf="cc.formulation=='Capsules'"><img class="pres_icon" src="assets/g_icon8.svg"></span>
                        <span *ngIf="cc.formulation==' Capsules'"><img class="pres_icon" src="assets/g_icon8.svg"></span>

                        <span *ngIf="cc.formulation=='Drops'"><img class="pres_icon" src="assets/g_icon9.svg"></span>
                        <span *ngIf="cc.formulation==' Drops'"><img class="pres_icon" src="assets/g_icon9.svg"></span>
                        <span *ngIf="cc.formulation=='Drop'"><img class="pres_icon" src="assets/g_icon9.svg"></span>

                        <span *ngIf="cc.formulation=='Oil'"><img class="pres_icon" src="assets/g_icon10.svg"></span>
                        <span *ngIf="cc.formulation==' Oil'"><img class="pres_icon" src="assets/g_icon10.svg"></span>

                        <span *ngIf="cc.formulation=='Gel'"><img class="pres_icon" src="assets/g_icon11.svg"></span>
                        <span *ngIf="cc.formulation==' Gel'"><img class="pres_icon" src="assets/g_icon11.svg"></span>

                        <span *ngIf="cc.formulation=='Powder'"><img class="pres_icon" src="assets/g_icon12.svg"></span>
                        <span *ngIf="cc.formulation==' Powder'"><img class="pres_icon" src="assets/g_icon12.svg"></span>

                        <span *ngIf="cc.formulation=='Syrup'"><img class="pres_icon" src="assets/g_icon13.svg"></span>
                        <span *ngIf="cc.formulation==' Syrup'"><img class="pres_icon" src="assets/g_icon13.svg"></span>

                        <span *ngIf="cc.formulation=='Sachet'"><img class="pres_icon" src="assets/g_icon14.svg"></span>
                        <span *ngIf="cc.formulation==' Sachet'"><img class="pres_icon" src="assets/g_icon14.svg"></span>

                        <span *ngIf="cc.formulation=='Vaccine'"><img class="pres_icon" src="assets/g_icon15.svg"></span>
                        <span *ngIf="cc.formulation==' Vaccine'"><img class="pres_icon" src="assets/g_icon15.svg"></span>

                        <span *ngIf="cc.formulation=='Inhaler'"><img class="pres_icon" src="assets/g_icon16.svg"></span>
                        <span *ngIf="cc.formulation==' Inhaler'"><img class="pres_icon" src="assets/g_icon16.svg"></span>

                        <span *ngIf="cc.formulation=='Others'"><img class="pres_icon" src="assets/g_icon17.svg"></span>
                        <span *ngIf="cc.formulation==' Others'"><img class="pres_icon" src="assets/g_icon17.svg"></span>


                        {{cc.brand}} <i style="color: #00AF69 ;font-size: 1rem;margin-left: 0.5rem;margin-right: 0.5rem;" class="fa fa-edit" (click)="edit(i)"></i> <i style="color: #00AF69 ;font-size: 1rem;" class="fa fa-close" (click)="remove(i)"></i>
                </div>

        </div>
</div>

<div style="margin-bottom: 1rem;" *ngIf="search==''&&block==1&&((doc_id!=4&&dmail=='rx4@rxcarenet.org')||(dmail!='rx389462@rxcarenet.org' && dmail!='rx483098@rxcarenet.org'))">
        <p style="color: #7D7D7D;">Frequently Used</p>

        <div [ngStyle]="{'max-height':data.toggle_alignment?'11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;">
                <div #cc_item class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
                        *ngFor="let cc of cc_show;let indexOfelement=index;let i = index" (click)="addCC(i)">

                        <span *ngIf="cc.category=='Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>
                        <span *ngIf="cc.category==' Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>

                        <span *ngIf="cc.category=='Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>
                        <span *ngIf="cc.category==' Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>

                        <span *ngIf="cc.category=='Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>
                        <span *ngIf="cc.category==' Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>

                        <span *ngIf="cc.category=='Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>
                        <span *ngIf="cc.category==' Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>

                        <span *ngIf="cc.category=='Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>
                        <span *ngIf="cc.category==' Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>

                        <span *ngIf="cc.category=='Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>
                        <span *ngIf="cc.category==' Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>

                        <span *ngIf="cc.category=='Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>
                        <span *ngIf="cc.category==' Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>

                        <span *ngIf="cc.category=='Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>
                        <span *ngIf="cc.category==' Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>

                        <span *ngIf="cc.category=='Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                        <span *ngIf="cc.category==' Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                        <span *ngIf="cc.category=='Drop'"><img class="pres_icon" src="assets/icon9.svg"></span>

                        <span *ngIf="cc.category=='Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>
                        <span *ngIf="cc.category==' Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>

                        <span *ngIf="cc.category=='Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>
                        <span *ngIf="cc.category==' Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>

                        <span *ngIf="cc.category=='Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>
                        <span *ngIf="cc.category==' Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>

                        <span *ngIf="cc.category=='Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>
                        <span *ngIf="cc.category==' Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>

                        <span *ngIf="cc.category=='Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>
                        <span *ngIf="cc.category==' Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>

                        <span *ngIf="cc.category=='Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>
                        <span *ngIf="cc.category==' Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>

                        <span *ngIf="cc.category=='Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>
                        <span *ngIf="cc.category==' Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>

                        <span *ngIf="cc.category=='Others'"><img class="pres_icon" src="assets/icon17.svg"></span>
                        <span *ngIf="cc.category==' Others'"><img class="pres_icon" src="assets/icon17.svg"></span>

                        {{cc.name}}
                </div>
        </div>

        <p *ngIf="cc_show.length==0">No frequently used items found</p>

</div>


<div style="margin-bottom: 1rem;" *ngIf="search!='' &&block==1">
        <p style="color: #7D7D7D;">Search Results</p>

        <div [ngStyle]="{'max-height':data.toggle_alignment?'11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;" infiniteScroll
                [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                [infiniteScrollDisabled]="isFullListDisplayed" [scrollWindow]="false">
                <div #cc_item class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
                        *ngFor="let cc of cc_show;let indexOfelement=index;let i = index" (click)="addCC(i)">

                        <span *ngIf="cc.category=='Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>
                        <span *ngIf="cc.category==' Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>

                        <span *ngIf="cc.category=='Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>
                        <span *ngIf="cc.category==' Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>

                        <span *ngIf="cc.category=='Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>
                        <span *ngIf="cc.category==' Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>

                        <span *ngIf="cc.category=='Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>
                        <span *ngIf="cc.category==' Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>

                        <span *ngIf="cc.category=='Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>
                        <span *ngIf="cc.category==' Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>

                        <span *ngIf="cc.category=='Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>
                        <span *ngIf="cc.category==' Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>

                        <span *ngIf="cc.category=='Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>
                        <span *ngIf="cc.category==' Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>

                        <span *ngIf="cc.category=='Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>
                        <span *ngIf="cc.category==' Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>

                        <span *ngIf="cc.category=='Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                        <span *ngIf="cc.category==' Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                        <span *ngIf="cc.category=='Drop'"><img class="pres_icon" src="assets/icon9.svg"></span>

                        <span *ngIf="cc.category=='Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>
                        <span *ngIf="cc.category==' Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>

                        <span *ngIf="cc.category=='Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>
                        <span *ngIf="cc.category==' Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>

                        <span *ngIf="cc.category=='Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>
                        <span *ngIf="cc.category==' Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>

                        <span *ngIf="cc.category=='Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>
                        <span *ngIf="cc.category==' Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>

                        <span *ngIf="cc.category=='Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>
                        <span *ngIf="cc.category==' Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>

                        <span *ngIf="cc.category=='Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>
                        <span *ngIf="cc.category==' Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>

                        <span *ngIf="cc.category=='Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>
                        <span *ngIf="cc.category==' Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>

                        <span *ngIf="cc.category=='Others'"><img class="pres_icon" src="assets/icon17.svg"></span>
                        <span *ngIf="cc.category==' Others'"><img class="pres_icon" src="assets/icon17.svg"></span>

                        {{cc.name}}
                </div>
        </div>

        <p *ngIf="cc_show.length==0">No results found</p>

</div>

<div style="margin-bottom: 1rem;" *ngIf="block==2">
        <p style="color: #7D7D7D;">Suggestions</p>

        <div [ngStyle]="{'max-height':data.toggle_alignment?'11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;">
                <div #cc_item class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
                        *ngFor="let cc of cc_show;let indexOfelement=index;let i = index" (click)="addCC(i)">

                        <span *ngIf="cc.category=='Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>
                        <span *ngIf="cc.category==' Injection'"><img class="pres_icon" src="assets/icon1.svg"></span>

                        <span *ngIf="cc.category=='Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>
                        <span *ngIf="cc.category==' Lotion'"><img class="pres_icon" src="assets/icon2.svg"></span>

                        <span *ngIf="cc.category=='Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>
                        <span *ngIf="cc.category==' Ointment'"><img class="pres_icon" src="assets/icon3.svg"></span>

                        <span *ngIf="cc.category=='Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>
                        <span *ngIf="cc.category==' Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>

                        <span *ngIf="cc.category=='Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>
                        <span *ngIf="cc.category==' Tablet'"><img class="pres_icon" src="assets/icon5.svg"></span>

                        <span *ngIf="cc.category=='Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>
                        <span *ngIf="cc.category==' Suspension'"><img class="pres_icon" src="assets/icon6.svg"></span>

                        <span *ngIf="cc.category=='Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>
                        <span *ngIf="cc.category==' Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>

                        <span *ngIf="cc.category=='Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>
                        <span *ngIf="cc.category==' Capsules'"><img class="pres_icon" src="assets/icon8.svg"></span>

                        <span *ngIf="cc.category=='Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                        <span *ngIf="cc.category==' Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                        <span *ngIf="cc.category=='Drop'"><img class="pres_icon" src="assets/icon9.svg"></span>

                        <span *ngIf="cc.category=='Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>
                        <span *ngIf="cc.category==' Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>

                        <span *ngIf="cc.category=='Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>
                        <span *ngIf="cc.category==' Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>

                        <span *ngIf="cc.category=='Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>
                        <span *ngIf="cc.category==' Powder'"><img class="pres_icon" src="assets/icon12.svg"></span>

                        <span *ngIf="cc.category=='Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>
                        <span *ngIf="cc.category==' Syrup'"><img class="pres_icon" src="assets/icon13.svg"></span>

                        <span *ngIf="cc.category=='Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>
                        <span *ngIf="cc.category==' Sachet'"><img class="pres_icon" src="assets/icon14.svg"></span>

                        <span *ngIf="cc.category=='Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>
                        <span *ngIf="cc.category==' Vaccine'"><img class="pres_icon" src="assets/icon15.svg"></span>

                        <span *ngIf="cc.category=='Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>
                        <span *ngIf="cc.category==' Inhaler'"><img class="pres_icon" src="assets/icon16.svg"></span>

                        <span *ngIf="cc.category=='Others'"><img class="pres_icon" src="assets/icon17.svg"></span>
                        <span *ngIf="cc.category==' Others'"><img class="pres_icon" src="assets/icon17.svg"></span>


                        {{cc.name}}
                </div>
        </div>

        <p *ngIf="cc_show.length==0">No results found</p>
</div>

<hr style="color: #7D7D7D;margin-bottom: 1rem;" *ngIf="(doc_id!=4&&dmail=='rx4@rxcarenet.org')&&(dmail!='rx389462@rxcarenet.org')">

<p style="color: #7D7D7D;">All</p>


<div style="margin-top: 00.5rem;">
        <div [ngStyle]="{'max-height':data.toggle_alignment?'11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;" infiniteScroll
                [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll2()"
                [infiniteScrollDisabled]="isFullListDisplayed" [scrollWindow]="false">
                <div #cc_item class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
                        *ngFor="let cc of cc_all;let indexOfelement=index;let i = index" (click)="addCC1(i)">
                        <span *ngIf="cc.name!=''">
                                <span *ngIf="cc.category=='Injection'"><img class="pres_icon"
                                                src="assets/icon1.svg"></span>
                                <span *ngIf="cc.category==' Injection'"><img class="pres_icon"
                                                src="assets/icon1.svg"></span>

                                <span *ngIf="cc.category=='Lotion'"><img class="pres_icon"
                                                src="assets/icon2.svg"></span>
                                <span *ngIf="cc.category==' Lotion'"><img class="pres_icon"
                                                src="assets/icon2.svg"></span>

                                <span *ngIf="cc.category=='Ointment'"><img class="pres_icon"
                                                src="assets/icon3.svg"></span>
                                <span *ngIf="cc.category==' Ointment'"><img class="pres_icon"
                                                src="assets/icon3.svg"></span>

                                <span *ngIf="cc.category=='Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>
                                <span *ngIf="cc.category==' Soap'"><img class="pres_icon" src="assets/icon4.svg"></span>

                                <span *ngIf="cc.category=='Tablet'"><img class="pres_icon"
                                                src="assets/icon5.svg"></span>
                                <span *ngIf="cc.category==' Tablet'"><img class="pres_icon"
                                                src="assets/icon5.svg"></span>

                                <span *ngIf="cc.category=='Suspension'"><img class="pres_icon"
                                                src="assets/icon6.svg"></span>
                                <span *ngIf="cc.category==' Suspension'"><img class="pres_icon"
                                                src="assets/icon6.svg"></span>

                                <span *ngIf="cc.category=='Cream'"><img class="pres_icon" src="assets/icon7.svg"></span>
                                <span *ngIf="cc.category==' Cream'"><img class="pres_icon"
                                                src="assets/icon7.svg"></span>

                                <span *ngIf="cc.category=='Capsules'"><img class="pres_icon"
                                                src="assets/icon8.svg"></span>
                                <span *ngIf="cc.category==' Capsules'"><img class="pres_icon"
                                                src="assets/icon8.svg"></span>

                                <span *ngIf="cc.category=='Drops'"><img class="pres_icon" src="assets/icon9.svg"></span>
                                <span *ngIf="cc.category==' Drops'"><img class="pres_icon"
                                                src="assets/icon9.svg"></span>
                                <span *ngIf="cc.category=='Drop'"><img class="pres_icon" src="assets/icon9.svg"></span>

                                <span *ngIf="cc.category=='Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>
                                <span *ngIf="cc.category==' Oil'"><img class="pres_icon" src="assets/icon10.svg"></span>

                                <span *ngIf="cc.category=='Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>
                                <span *ngIf="cc.category==' Gel'"><img class="pres_icon" src="assets/icon11.svg"></span>

                                <span *ngIf="cc.category=='Powder'"><img class="pres_icon"
                                                src="assets/icon12.svg"></span>
                                <span *ngIf="cc.category==' Powder'"><img class="pres_icon"
                                                src="assets/icon12.svg"></span>

                                <span *ngIf="cc.category=='Syrup'"><img class="pres_icon"
                                                src="assets/icon13.svg"></span>
                                <span *ngIf="cc.category==' Syrup'"><img class="pres_icon"
                                                src="assets/icon13.svg"></span>

                                <span *ngIf="cc.category=='Sachet'"><img class="pres_icon"
                                                src="assets/icon14.svg"></span>
                                <span *ngIf="cc.category==' Sachet'"><img class="pres_icon"
                                                src="assets/icon14.svg"></span>

                                <span *ngIf="cc.category=='Vaccine'"><img class="pres_icon"
                                                src="assets/icon15.svg"></span>
                                <span *ngIf="cc.category==' Vaccine'"><img class="pres_icon"
                                                src="assets/icon15.svg"></span>

                                <span *ngIf="cc.category=='Inhaler'"><img class="pres_icon"
                                                src="assets/icon16.svg"></span>
                                <span *ngIf="cc.category==' Inhaler'"><img class="pres_icon"
                                                src="assets/icon16.svg"></span>

                                <span *ngIf="cc.category=='Others'"><img class="pres_icon"
                                                src="assets/icon17.svg"></span>
                                <span *ngIf="cc.category==' Others'"><img class="pres_icon"
                                                src="assets/icon17.svg"></span>


                                {{cc.name}}</span>
                </div>
        </div>

        <p *ngIf="cc_all.length==0">No results found</p>

</div>