import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
//import { ErrorDialogService } from 'src/app/services/errordialog.service';
import {
  HttpClient,
  HttpEventType,
  HttpErrorResponse,
  HttpEvent
} from "@angular/common/http";
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
//import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import * as html2pdf from 'html2pdf.js';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
declare let alasql;

@Component({
  selector: 'app-dailycollection',
  templateUrl: './dailycollection.component.html',
  styleUrls: ['./dailycollection.component.scss']
})

export class DailycollectionComponent implements OnInit {
  
  @Input() headerTitle: string;
  paymentCategoryForm: FormGroup;
  
  params = {};
  getCollectionList:any;
  getCollectionRsultList:any;

  dmail;
  doc_id ;
  token;
  drId: number;
  private sub: any;

  feesCharged: any;
  feesPaid: any;
  feesPending: any;
  feesRefund: any;
  feesVoicecall: number;
  feesIvrs: number;
  feesEmerVideoConsu: number;
  feesChat: number;
  feesConsu: number;
  feesEmerVideoAppo: number;
  feesVideoCall:number;
  feesEmerVoicecall: number;
  feesRegistration: number;
  feesAppo: number;
  totalPending: number;
  totalRefund: number;
  totalCollected: number;
  
  // exportAsConfigPdf: ExportAsConfig = {
  //   type: 'pdf', // the type you want to download
  //   elementIdOrContent: 'mytable', // the id of html/table element
  // }

  // exportAsConfigCsv: ExportAsConfig = {
  //   type: 'csv', 
  //   elementIdOrContent: 'mytable', 
  // }
todayDate:any;
startdateInFormat = '-';
enddateInFormat = '-';
selected: any;
alwaysShowCalendars: boolean;
maxDate = moment();
minDate =  moment();
ranges: any = {
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
}
invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];


  constructor( private route: ActivatedRoute,  private toastr: ToastrService,  private appService: AppService,private http: HttpClient, private fb: FormBuilder, private router: Router) {
    this.alwaysShowCalendars = true;
   }

  ngOnInit(): void {

    let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
    this.dmail= getauthdetails.account_id;
    this.doc_id = getauthdetails.doctor_id;
    this.token = getauthdetails.token;

    let date = new Date();
   let momDate = moment(date);
   this.todayDate = momDate.format('DD/MM/YYYY');
   let sendDate = momDate.format('YYYY-MM-DD')
   this. getCollectionReport(sendDate);
  }

  isInvalidDate = (m: moment.Moment) =>  {
    return this.invalidDates.some(d => d.isSame(m, 'day') )
  }

  rangeClicked(range) {
  }

  datesUpdated(range) {
   
   const startDate = moment(this.selected.startDate.toDate());
   const endDate = moment(this.selected.endDate.toDate());
   this.startdateInFormat = startDate.format('DD/MM/YYYY');
   this.enddateInFormat = endDate.format('DD/MM/YYYY');
     
   let sendstartDate = startDate.format('YYYY-MM-DD');
   let sendendDate = endDate.format('YYYY-MM-DD');
   this. getCollectionReport(sendstartDate);

  }

  downloadPDF(){
     var element = document.getElementById('mytable');
  var opt = {
    margin: 0.1,
    filename: 'DailyCollectionReport.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
    
};
 // New Promise-based usage:
 html2pdf().from(element).set(opt).save();
  }

  downloadCSV(){
    let filename = 'mycsv'
    alasql("SELECT * INTO CSV('" + filename + ".csv', {headers:true,separator:','}) FROM HTML('#mytable',{headers:true,separator:','})");
  };

  downloadXLS(){
    var blob = new Blob([document.getElementById('mytable').innerHTML], {
      type: "application/vnd.ms-excel;charset=utf-8"
    })
    saveAs(blob, "DailyCollectionReport.xls");
  }

  getCollectionReport(filterdate){ 

    this.getCollectionList = '';
  this.getCollectionRsultList = '';
  this.feesCharged = '';
          this.feesPaid= '';
          this.feesPending = '';
          this.feesRefund = '';

    this.params = {
      "account_email": this.dmail,
      "date": filterdate
    }

  this.appService
      .getData(AppConfig.settings.EndPoints.dailyCollectionReport, this.params, localStorage.getItem('TOKEN'))
      .subscribe((response: any = []) => {
        this.getCollectionList = response.consolidated;
        this.getCollectionRsultList = response.result;

        this.getCollectionList.map(res => {
          this.feesCharged = res.total_amount_raised;
          this.feesPaid= res.total_paid;
          this.feesPending = res.total_pending;
          this.feesRefund = res.total_refunded;
          this.feesVoicecall = res.voice_call;
          this.feesIvrs = res.ivrs;
          this.feesEmerVideoConsu = res.emergency_video_call_consultation;
          this.feesChat = res.chat;
          this.feesConsu = res.consultation;
          this.feesEmerVideoAppo = res.emergency_video_call_appointment_fee;
          this.feesVideoCall = res.video_call_appointment_fee;
          this.feesEmerVoicecall = res.emergency_voice_call;
          this.feesRegistration = res.registration_fee;
          this.feesAppo = res.appointment;
          this.totalPending = res.total_pending;
          this.totalRefund = res.total_refunded;
          this.totalCollected = res.total_paid;
        });
    });
  }

}
