import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import domtoimage from 'dom-to-image';
import { environment } from 'src/environments/environment';

import { AppService } from 'src/app/app.service';
import { AppConfig } from 'src/app/app.config';
import { AppConstants } from 'src/app/constants/AppConstants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CdkDrag, CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';



import { Toast, ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { PrintService } from '../services/print.service';
import { VoiceRecognitionService } from '../service/voice-recognition.service';
import { DoCheck, KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import Speech from 'speak-tts';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { Inject } from '@angular/core';
// import { Console } from 'node:console';

@Component({
  selector: 'app-vaccination',
  templateUrl: './vaccination.component.html',
  styleUrls: ['./vaccination.component.scss']
})
export class VaccinationComponent implements OnInit {
  
  accountemail;
  patientemail;
  patientdob;
  patientgender;
  //  = "1998-05-13";
  token = "b2F1dGhfdG9rZW49aWJrOEpYUXJUbVgzbk9ra2tIb3gmb2F1dGhfdG9rZW5fc2VjcmV0PU1RQnFOenJ3MGNZQzFPZ0huWmNmJmFjY291bnRfaWQ9cng0JTQwcnhjYXJlbmV0Lm9yZw==";
  imageForm;
  count1 = [];
  urlPoints;
  getapi;
  vaccinelist;
  progress_bar = true;
  Reschedule;
  
  agegroup:any = [];
  agedate:any = [];
  ageinmonths;
  agestatus:any = [];
  agechild:any = [];
  subchild:any = [];
  givenvac:any = [];
  brandlist:any = [];
  batchimage:any = [];
  subvac:any = [];
  closesidebar = false;
  drugname;
  url;
  brand = false;
  branddata:any = [];
  selectedvaccine;
  // minDate = moment().subtract(1, 'year')
  minDate: Date;
  
  sub;
  vaccineparams;
  brandparams;
  mybrand:any = [];
  dependencyexists;

  uploadedimg;
  progress : any=0;
  patientheader;
  groupVaccine;
  vacccinedetail:any = [];
  hidepatientheader = false;
  datepick = false;
  brand_name;
  dependency;
  imgenvironment = environment.HOST
  

  carddeffer;
  cardgiven;
  cardgivenbyother;
  givenid;

  call_pending;
  did;
  hospital;
  id;
 mode;
 patientdata;
 age;
 check;
 verticalarray:any = [[0,1],[2],[3],[4,5],[6,7,8],[9,10,11,12,13,14],[15,16,17,18],[19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48],[49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71],[72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120],[121,122,123,124,125,126,127,128,129,130,131],[132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168],[169,170,171,172,173,174,175,176,177,178,179],[180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216]]
 
 openrescheduleddate;
batch_image;

 mymodel = false;
 composite = true;
 hideall = true;
 showdeffer = false;
 groupvaccine:any = [];

 subvaccines:any = [];
 sub_vaccines:any = [];

 deferreddate = false;
 todaydate = new Date();
 
 currentvaccine
 imageForm2
 vacimg;
 givenvacimg;
 duevacimg;
 category;
 adherence;
 hideaherence = true
 imageandcomment;
 showimageupload = false;
 my_brand; 
 serializedDate;

 reschedulevaccine;
 textForm;
 image_Form;

 imagegiven = false;
 textselectbrand = false;
 showcheckbox;
 showcheckboxdeffer;

 batchimageshow = false;
 subvacsno:any =[];

 actualimage;
 maxDate;
 duedate;

  constructor(private spinner: NgxSpinnerService , private fb: FormBuilder, private appService: AppService, public dialog: MatDialog, private toastr: ToastrService, private dom: DomSanitizer, private route: ActivatedRoute, private router: Router, public printService: PrintService, public service: VoiceRecognitionService, private differs: KeyValueDiffers) { 
    this.urlPoints = AppConfig.settings.EndPoints;

    this.minDate = new Date();
    this.maxDate = new Date();
    
   var date = new FormControl(new Date());
    this.serializedDate = new FormControl((new Date()).toISOString());
    
    this.image_Form = this.fb.group({
      imagegivendate : ["", [Validators.required]],
      imagegiven:[""],
  
    })

    this.textForm = this.fb.group({
      textgivendate : ["", [Validators.required]],
      texthere: ["",[Validators.required]],
  
    })

    
    this.imageForm = this.fb.group({
      // image: ["", [Validators.required]],
      selecteddate: ["" , []],
      my_brand:["",[]]
    })

    // this.imageForm.controls['image'].setValue("");
    // this.imageForm.controls['selecteddate'].setValue("");

    this.imageForm2 = this.fb.group({
      texthere: ["", []],
      selecteddate2: ["" , []]
    })


    this.reschedulevaccine = this.fb.group({
       reschedulevaccinedate:["",[Validators.required]]
    })

    // this.imageForm2.controls['texthere'].setValue("");
    // this.imageForm2.controls['selecteddate2'].setValue("");

    var patient_data = sessionStorage.getItem("patientprofile");
    this.patientdata  = JSON.parse(patient_data)
    this.age = sessionStorage.getItem("dob");
    

    this.sub = this.route.queryParams
    .subscribe(params => {
      this.patientdob = params['patientdob']
      this.patientemail = params['patientemail']
      this.token = params['token']
      this.accountemail = params['dmail']
      this.did = params["did"]
      this.hospital = params["hospital"]
      this.id = params["id"]
      this.mode = params["mode"]
    });


    this.vaccineparams = {
      "account_email": this.accountemail,
      "patient_email" : this.patientemail,
      "dob": this.patientdob
    }
    this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token)

 
  

  }
  ngOnInit(): void {

      var vacccineadherencedetailsparams = {
      "account_email": this.accountemail,
      "patient_email": this.patientemail
    }

    this.vacccineadherence_details(this.urlPoints.vacccineadherencedetails , vacccineadherencedetailsparams , this.token)
    
    var patientdetailsvaccinationparams = {
      "account_email": this.accountemail,
      "patient_email": this.patientemail
    }

    this.patientdetails_vaccination(this.urlPoints.patientdetailsvaccination , patientdetailsvaccinationparams , this.token)


  }

  
  vacccineadherence_details(url,params, token){
    this.appService.getData(url, params, token).subscribe((response: any) => {
      if(response.success == 1){
        this.hideaherence = true;
        this.adherence = response
      }else if(response == []){
        this.hideaherence = false;
      }
      
  });
  }

    patientdetails_vaccination(url, params, token){
      this.appService.getData(url, params, token).subscribe((response: any) => {
          if(response.success == 1){
            this.patientheader = "";
            this.hidepatientheader = true;
            this.patientheader = response.details[0];
          }else{
              this.hidepatientheader = false;
          }
      });
    }

  gotoconsultation(){
     var id = sessionStorage.getItem("id");
     var did = sessionStorage.getItem("did");
     this.hospital = sessionStorage.getItem("hospital");

    //  http://localhost:4200/#/?id=19db6e56-1d75-4c2f-bce0-7857a282e082&dmail=rx4@rxcarene[…]LYmptWlpVd3VpJmFjY291bnRfaWQ9cng0JTQwcnhjYXJlbmV0Lm9yZw%3D%3D
     this.router.navigate(["/epicare/appointment"]);
  }


  givenbyother(data, data1){
    this.mybrand = [];
    this.batchimageshow = true;
    this.showimageupload = true;
    this.deferreddate = false;
    this.imagegiven = true;
    this.url = "";
    this.datepick = true;
    this.composite = true;
    this.category = data
    this.openrescheduleddate = true; 
 
    this.givenid = data1.id
    let str = data1.brand_name;
    var mybrands = str.split(",");
    
    for(var i=0;i<mybrands.length;i++){
      this.mybrand.push(mybrands[i]);
    }


    var vacccinedetailsparams = {
      "account_email": this.accountemail,
      "patient_email": this.patientemail,
      "sno": data1.sno
    }

    this.vacccine_details(this.urlPoints.vacccinedetails , vacccinedetailsparams , this.token)
   
  }

  rescheduled(data , status){
    this.showcheckbox = false;
    this.datepick = false;
    this.imagegiven = false;
    this.batchimageshow = false;
    this.deferreddate = true;
    this.composite = false;
    this.category = status;
    this.showimageupload = false;
     this.openrescheduleddate = true; 
  }


  togglebatch(){
       this.batch_image = !this.batch_image
  }

  addcompositedeffer(){
      this.showcheckboxdeffer = !this.showcheckboxdeffer
  }


  addcomposite(){
      this.showcheckbox = !this.showcheckbox; 
  }

  checkedboxdeffer(data , id , e){
  
    if(e.target.checked == true){
      if(data != this.selectedvaccine){
        this.sub_vaccines.push(data);
      }
    }else{
      for(var i=0;i<this.sub_vaccines.length;i++){
        if(this.sub_vaccines[i].drug_name == id){
            this.sub_vaccines.splice(i, 1); 
        }
      }

    }

  }

  checkedbox(data , id , e){
  
    if(e.target.checked == true){
      if(data != this.selectedvaccine){
        this.subvaccines.push(data);
      }
    }else{
      for(var i=0;i<this.subvaccines.length;i++){
        if(this.subvaccines[i].drug_name == id){
            this.subvaccines.splice(i, 1); 
        }
      }

    }

  }

  removecomposite(data){
    for(var i=0;i<this.subvaccines.length;i++){
      if(this.subvaccines[i].drug_name == data.drug_name){
          this.subvaccines.splice(i, 1); 
      }
    }
  }

  remove_composite(data){
    for(var i=0;i<this.sub_vaccines.length;i++){
      if(this.sub_vaccines[i].drug_name == data.drug_name){
          this.sub_vaccines.splice(i, 1); 
      }
    }
  }

  savecompositebrand(dategiven){
    
    for(var i=0;i<this.subvaccines.length;i++){
        this.subvacsno.push(this.subvaccines[i].sno)
    }

    var params = {
      "patient_email": this.patientemail,
      "account_email": this.accountemail,
      "master_vaccine_sno": this.selectedvaccine.sno,
      "sub_vaccine_sno": this.subvacsno,
      "date_given": dategiven,
      "due_date": this.selectedvaccine.due_date
    } 
    //changes
    this.appService.postData(this.urlPoints.addcompositevaccination, params, this.token).subscribe((response: any) => {
    
      if(response.success == 1){
        this.spinner.hide();
      }else{
        this.toastr.error(response.message);
      }
 });

  }

  savegivenimage(){
    
    if(this.image_Form.valid && this.uploadedimg  != ""){
      if(this.brand_name != undefined){
        this.textselectbrand = false;
        this.spinner.show()
       if(this.uploadedimg != undefined){
        this.actualimage = this.uploadedimg.image[0].filename
       }else{
         this.actualimage = ''
       }
        var newdate = moment(new Date(this.image_Form.value.imagegivendate)).format("YYYY-MM-DD");
        this.savecompositebrand(newdate);
        var paramsgiven = {
          "s_no": this.selectedvaccine.sno,
          "status":"adminsitrated-1",
          "patient_email":this.patientemail,
          "account_email": this.accountemail,
          "image_url": this.actualimage,
          "date_given": newdate,
          "brand_name":this.brand_name,
          "due_date": this.selectedvaccine.due_date
        }

        this.appService.postData(this.urlPoints.updatevaccinationstatus, paramsgiven, this.token).subscribe((response: any) => {

          if(response.success == 1){          
            this.spinner.hide();
            this.vaccineparams = {
              "account_email": this.accountemail,
              "patient_email" : this.patientemail,
              "dob": this.patientdob
            }
            this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
            this.toastr.success(response.message);
            this.close_btn();

            
            this.showcheckbox = false;
            var patientdetailsvaccinationparams = {
              "account_email": this.accountemail,
              "patient_email": this.patientemail
            }
        
          this.patientdetails_vaccination(this.urlPoints.patientdetailsvaccination , patientdetailsvaccinationparams , this.token)
          this.vacccineadherence_details(this.urlPoints.vacccineadherencedetails , patientdetailsvaccinationparams  , this.token)
            
          }else{
            this.spinner.hide()
          }
          });


      }else{
        this.textselectbrand = true;
      }
    }
  }

  savegiventext(){
    var newdate = moment(new Date(this.textForm.value.textgivendate)).format("YYYY-MM-DD");
    if(this.textForm.valid){
      if(this.brand_name != undefined){
        this.textselectbrand = false;
        this.savecompositebrand(newdate);
        var params_given = {
          "s_no": this.selectedvaccine.sno,
          "status":"adminsitrated-1",
          "patient_email":this.patientemail,
          "account_email": this.accountemail,
          "comments": this.textForm.value.texthere,
          "brand_name":this.brand_name,
          "date_given": newdate,
          "due_date": this.selectedvaccine.due_date
        }

        this.appService.postData(this.urlPoints.updatevaccinationstatus, params_given, this.token).subscribe((response: any) => {
  
          if(response.success == 1){          
            this.spinner.hide()
            this.vaccineparams = {
              "account_email": this.accountemail,
              "patient_email" : this.patientemail,
              "dob": this.patientdob
            }
            this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
            this.toastr.success(response.message);
            this.close_btn();
            this.showcheckbox = false;
           
            var patientdetailsvaccinationparams = {
              "account_email": this.accountemail,
              "patient_email": this.patientemail
            }
        
            this.patientdetails_vaccination(this.urlPoints.patientdetailsvaccination , patientdetailsvaccinationparams , this.token)
            this.vacccineadherence_details(this.urlPoints.vacccineadherencedetails , patientdetailsvaccinationparams  , this.token)
            
          }else{
            this.spinner.hide()
          }
          });

      }else{
        this.textselectbrand = true;
      }
    }
  }

  saverescheduletext(){
    this.addedrelatedvaccine();
    if(this.imageForm2.valid){
    var newdate = moment(new Date(this.imageForm2.value.selecteddate2)).format("YYYY-MM-DD");
    
    if(this.category == 'Given'){
      
      this.cardgivenbyother = true
      if(this.cardgivenbyother == true){
        this.spinner.show()
      var paramsgiven = {
        "s_no": this.selectedvaccine.sno,
        "status":"adminsitrated-1",
        "patient_email":this.patientemail,
        "account_email": this.accountemail,
        "comments": this.imageForm2.value.texthere,
        "brand_name":this.brand_name,
        "date_given": newdate,
      }
      this.appService.postData(this.urlPoints.updatevaccinationstatus, paramsgiven, this.token).subscribe((response: any) => {
        if(response.success == 1){          
          this.spinner.hide()
          this.toastr.success(response.message);

          var patientdetailsvaccinationparams = {
            "account_email": this.accountemail,
            "patient_email": this.patientemail
          }
      
          this.patientdetails_vaccination(this.urlPoints.patientdetailsvaccination , patientdetailsvaccinationparams , this.token)

          this.close_btn()
          
        }else{
          this.spinner.hide()
        }
        });
      }
     }

  }
}

removedrug(data){
  for(var i=0;i<this.groupvaccine.length;i++){
    if(this.groupvaccine[i].drug_name == data){
      this.groupvaccine.splice(i,1)
    }
  }

}

selectedgroup(data){
  if(data == "6 weeks"){
    for(var i=0;i<this.vaccinelist[1][1].length;i++){
      this.subvaccines.push(this.vaccinelist[1][1][i]);
      this.subvaccines = [...new Set(this.subvaccines)];
    }
  }
 
}

removeimage(){
  this.url = "";
}


  savereschedule(){
    if(this.imageForm.valid){
    if(this.category == ('reschedule' || 'rescheduled')){
    var newdate = moment(new Date(this.imageForm.value.selecteddate)).format("YYYY-MM-DD");

    if(newdate != "1970-01-01"){
      var params =   {
        "account_email": this.accountemail,
        "s_no": this.selectedvaccine.sno,
        "patient_email": this.patientemail,
        "schedule_day": newdate,
      }
      this.appService.postData(this.urlPoints.reschdulevaccinationcalender, params, this.token).subscribe((response: any) => {
            if(response.success == 1){
              this.vaccineparams = {
                "account_email": this.accountemail,
                "patient_email" : this.patientemail,
                "dob": this.patientdob
              }
              this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
              this.toastr.success(response.message);
            }  
      });

    }else{
      this.toastr.error("Please Input Rescheduled Date")
    }

   }

   if(this.category == 'Given'){
    this.addedrelatedvaccine()
    this.cardgivenbyother = true 
    if(this.uploadedimg  != ""){
    if(this.cardgivenbyother == true){
      this.spinner.show()
      var actualimage = this.uploadedimg.image[0].filename
      var newdate = moment(new Date(this.imageForm.value.selecteddate)).format("YYYY-MM-DD");
    var paramsgiven = {
      "s_no": this.selectedvaccine.sno,
      "status":"adminsitrated-1",
      "patient_email":this.patientemail,
      "account_email": this.accountemail,
      "image_url": actualimage,
      "date_given": newdate,
      "brand_name":this.brand_name
    }
    this.appService.postData(this.urlPoints.updatevaccinationstatus, paramsgiven, this.token).subscribe((response: any) => {
      if(response.success == 1){
        this.spinner.hide()
        this.toastr.success(response.message);

        var patientdetailsvaccinationparams = {
          "account_email": this.accountemail,
          "patient_email": this.patientemail
        }
    
        this.patientdetails_vaccination(this.urlPoints.patientdetailsvaccination , patientdetailsvaccinationparams , this.token)
        this.showcheckbox = false;
        this.close_btn();

      }else{
        this.spinner.hide()
      }
      });
    }
    }else{
      this.toastr.error("Upload Batch Image");
    }
   }


  }
}

  addedrelatedvaccine(){
    var allsno = []
    for(var i=0;i< this.groupvaccine.length ; i++){
        allsno.push(this.groupvaccine[i].sno)
    }
    var params = {
      "patient_email": this.patientemail,
      "account_email": this.accountemail,
      "master_vaccine_sno": this.selectedvaccine.sno,
      "sub_vaccine_sno": allsno
  }

    this.appService.postData(this.urlPoints.addcompositevaccination, params, this.token).subscribe((response: any) => {
         this.groupvaccine = []
         if(response.success == 1){
           this.toastr.success(response.message);

           this.vaccineparams = {
            "account_email": this.accountemail,
            "patient_email" : this.patientemail,
            "dob": this.patientdob
          }
          this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
          this.close_btn();

         }else{
           this.toastr.error(response.message);
         }
    });

  }

  group_vaccine(){
    this.groupvaccine.push(this.currentvaccine);
    this.groupvaccine = [...new Set(this.groupvaccine)];
     
  }

  saverescheduledate(){
    // this.selectedvaccine.sno
    var vacarray:any = [];
    for(var i=0;i<this.sub_vaccines.length;i++){
      vacarray.push(this.sub_vaccines[i].sno)
    }
     if(this.reschedulevaccine.valid){

       var newdate = moment(new Date(this.reschedulevaccine.value.reschedulevaccinedate)).format("YYYY-MM-DD");
       var params =   {
        "account_email": this.accountemail,
        "s_no": vacarray,
        "master_vaccine_sno": this.selectedvaccine.sno,
        "patient_email": this.patientemail,
        "schedule_day": newdate,
        "due_date": this.selectedvaccine.due_date
       }

       this.appService.postData(this.urlPoints.reschdulevaccinationcalender, params, this.token).subscribe((response: any) => {
        this.showcheckboxdeffer = false;    
        if(response.success == 1){
               this.vaccineparams = {
                 "account_email": this.accountemail,
                 "patient_email" : this.patientemail,
                 "dob": this.patientdob
               }
               this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
               this.toastr.success(response.message);
               this.close_btn();
             }  
       });



     }
  }

  reschedule(){
    this.Reschedule = !this.Reschedule
    if(this.Reschedule == true){
      this.spinner.show()
      var params = {
        "s_no": this.selectedvaccine.sno,
        "status": 'rescheduled',
        "patient_email":this.patientemail,
        "account_email": this.accountemail
      }
      this.appService.postData(this.urlPoints.updatevaccinationstatus, params, this.token).subscribe((response: any) => {
        if(response.success == 1){
          this.vaccineparams = {
            "account_email": this.accountemail,
            "patient_email" : this.patientemail,
            "dob": this.patientdob
          }
          this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token)
             this.toastr.success(response.message);
             this.spinner.hide()
             this.close_btn();
        }else{
          this.spinner.hide()
        }
        });
    }
  }

  printvac(){
      //this.hospital = sessionStorage.getItem("hospital");
      let getauthdetails = JSON.parse(sessionStorage.getItem("authDetails"));
      this.hospital = getauthdetails.hospital_name;
     const dialogRef = this.dialog.open(DialogOverviewExampleDialogprintvaccine, {
      width: '300px',
      data: { option: 1 },
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

        if(result == 'allvac'){
          var node = document.getElementById('print_vactable');
          document.getElementById('print_vactable').style.display='block'
          var options = {quality: 1};
          domtoimage.toJpeg(node, options).then((dataUrl) => {
            this.vacimg = dataUrl
            document.getElementById('print_vactable').style.display='none'
            setTimeout(function(){ 
              var divToPrint = document.getElementById('printallimg')
              var popupWin = window.open('', '_blank', 'width=1000,height=1000');
              popupWin.document.open();
              popupWin.document.write('<html><style>body{margin-top:100px;margin-right:200px;} #myimage{margin-top:260px;margin-left:-180px;} #allspan{margin-top:10px;} #hospital{text-align: center !important;font-size: 20px !important;margin-left: 40%;}</style><body onload="window.print()">'+divToPrint.innerHTML + '</html>');
              popupWin.document.close();
             }, 1000);
         })

        }else if(result == 'givenvac'){
          var node = document.getElementById('print_givenvactable');
          document.getElementById('print_givenvactable').style.display='block'
          var options = {quality: 1};
          domtoimage.toJpeg(node, options).then((dataUrl) => {
            this.duevacimg = dataUrl
            document.getElementById('print_givenvactable').style.display='none'
            setTimeout(function(){ 
              var divToPrint = document.getElementById('printdueimg')
              var popupWin = window.open('', '_blank', 'width=1000,height=1000');
              popupWin.document.open();
              popupWin.document.write('<html><style>body{margin-top:100px;margin-right:200px;} #duemyimage{margin-top:210px;margin-left:-180px;} #allspan{margin-top:10px;} #hospital{text-align: center !important;font-size: 20px !important;margin-left: 40%;}</style><body onload="window.print()">'+divToPrint.innerHTML + '</html>');
              popupWin.document.close();
             }, 1000);
         })

        }else if(result == 'duevac'){
          
          var node = document.getElementById('print_duevactable');
          document.getElementById('print_duevactable').style.display='block'
          var options = {quality: 1};
          domtoimage.toJpeg(node, options).then((dataUrl) => {
            this.givenvacimg = dataUrl
            document.getElementById('print_duevactable').style.display='none'
            setTimeout(function(){ 
              var divToPrint = document.getElementById('printgivenimg')
              var popupWin = window.open('', '_blank', 'width=1000,height=1000');
              popupWin.document.open();
              popupWin.document.write('<html><style>body{margin-top:100px;margin-right:200px;} #givenmyimage{margin-top:230px;margin-left:-180px;} #allspan{margin-top:10px;} #hospital{text-align: center !important;font-size: 20px !important;margin-left: 40%;}</style><body onload="window.print()">'+divToPrint.innerHTML + '</html>');
              popupWin.document.close();
             }, 1000);
         })

        }
    });

  }

  givenonduedate(){
    this.openrescheduleddate = false;
     this.cardgiven = true;
     this.deferreddate = false;
     this.imagegiven = false;
     this.batchimageshow = false;

     const dialogRef = this.dialog.open(DialogOverviewExampleDialogaddscanreport, {
      width: '700px',
      data: { option: 1 },
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'yes'){
        this.spinner.show()
        this.spinner.show()
      var params = {
        "s_no": this.selectedvaccine.sno,
        "status": '',
        "patient_email":this.patientemail,
        "account_email": this.accountemail,
        "comments": ""
      }
      this.appService.postData(this.urlPoints.updatevaccinationstatus, params, this.token).subscribe((response: any) => {
        this.spinner.hide()
        if(response.success == 1){
          this.vaccineparams = {
            "account_email": this.accountemail,
            "patient_email" : this.patientemail,
            "dob": this.patientdob
          }
          this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token)
             this.toastr.success(response.message);
             this.spinner.hide()
        }else{
          this.spinner.hide()
        }
        });

      }
    });

     
    
  }

  callpending(){
    this.openrescheduleddate = false;
    this.batchimageshow = false;
    this.call_pending = true;
    this.deferreddate = false;
    this.imagegiven = false;

    const dialogRef = this.dialog.open(DialogOverviewExampleDialogpending, {
      width: '700px',
      data: { option: 1 },
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'yes'){
          this.spinner.show()
        var params = {
          "s_no": this.selectedvaccine.sno,
          "status":"Pending",
          "patient_email":this.patientemail,
          "account_email": this.accountemail,
          "comments": "",
          "due_date": this.selectedvaccine.due_date
        }
        this.appService.postData(this.urlPoints.updatevaccinationstatus, params, this.token).subscribe((response: any) => {
          this.spinner.hide();
          if(response.success == 1){
            this.vaccineparams = {
              "account_email": this.accountemail,
              "patient_email" : this.patientemail,
              "dob": this.patientdob
            }
            this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
               this.toastr.success(response.message);
               this.close_btn();
          }else{
            this.spinner.hide()
          }
          });
        
      }
    });


    
  }

  calldefeered(){
    this.openrescheduleddate = false;
    this.batchimageshow = false;
    this.deferreddate = false;
    this.imagegiven = false;
    const dialogRef = this.dialog.open(DialogOverviewExampleDialogdeffer, {
      width: '700px',
      data: { option: 1 },
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'yes'){
          this.spinner.show()
        var params = {
          "s_no": this.selectedvaccine.sno,
          "status":"Deferred",
          "patient_email":this.patientemail,
          "account_email": this.accountemail,
          "comments": "",
          "due_date": this.selectedvaccine.due_date
        }
        this.appService.postData(this.urlPoints.updatevaccinationstatus, params, this.token).subscribe((response: any) => {
          if(response.success == 1){
            this.vaccineparams = {
              "account_email": this.accountemail,
              "patient_email" : this.patientemail,
              "dob": this.patientdob
            }
            this.getall_vacccines(this.urlPoints.vaccinationdruglist, this.vaccineparams, this.token);
            this.spinner.hide()
            this.close_btn();
            this.toastr.success(response.message);
          }else{
            this.spinner.hide()
          }
          });
        
      }
    });
    
  }

  

  getall_vacccines(url, params, token) {
    this.subchild = [];
    this.agegroup = [];
    this.agedate=[];
    this.ageinmonths = [];
    this.agestatus =[];
    this.appService.getData(url, params, token).subscribe((response: any) => {
       this.vaccinelist = response       

       for(var i=0;i< this.vaccinelist.length ; i++){
        this.agegroup.push(this.vaccinelist[i][0][0]);
        this.ageinmonths.push(this.vaccinelist[i][0][4])
        this.agedate.push(this.vaccinelist[i][0][1]);
        this.agestatus.push(this.vaccinelist[i][0][2]);
         for(var j=0;j< this.vaccinelist[i][1].length;j++){
           this.agechild.push(this.vaccinelist[i][1][j]);
         }
         this.subchild.push(this.agechild)
         this.agechild = [];
       }

       for(var i=0;i<1;i++){
        this.agegroup.push('');
       }


      //  alert()

      // for(var i=0;i<=216;i++){
      //   if(i == 0){
      //     this.verticalarray.push([i]);
      //   }else if(i == 1){
      //     this.verticalarray.push([i]);
      //   }else if(i == 2){
      //     this.verticalarray.push([i]);
      //   }else if(i == 3){
      //     var array4 = [];
      //     for(var i=5;i<=8;i++){
      //        array4.push(i)
      //     }
      //     this.verticalarray.push(array4);
      //   }else if(i == 5){
      //     var array5 = [];
      //     for(var i=5;i<=8;i++){
      //        array5.push(i)
      //     }
      //     this.verticalarray.push(array5);
      //   }else if(i == 9){
      //     var array6 = [];
      //     for(var i=9;i<=14;i++){
      //       array6.push(i)
      //    }
      //     this.verticalarray.push(array6);
      //   }else if(i == 15){
      //     var array7 = [];
      //     for(var i=15;i<=18;i++){
      //       array7.push(i)
      //    }
      //     this.verticalarray.push(array7);
      //   }else if(i == 19){
      //     var array8 = [];
      //     for(var i=19;i<=48;i++){
      //       array8.push(i)
      //    }
      //     this.verticalarray.push(array8);
      //   }else if(i == 49 ){
      //     var array9 = [];
      //     for(var i=49;i<=120;i++){
      //       array9.push(i)
      //    }
      //     this.verticalarray.push(array9);
      //   }else if(i == 121){
      //     var array10 = [];
      //     for(var i=121;i<=168;i++){
      //       array10.push(i)
      //    }
      //     this.verticalarray.push(array10);
      //   }else if(i == 169){
      //      var array11 = [];
      //     for(var i=169;i<=216;i++){
      //       array11.push(i)
      //    }
      //     this.verticalarray.push(array11);
      //   }
      // }



    });
  }

  vaccine_brandlist(url, params, token) {
    this.appService.getData(url, params, token).subscribe((response: any) => {
       if(response.success == 1){
           this.brandlist = response.details;
       }
    });
  }

  vacccine_details(url,params,token){
    this.appService.getData(url, params, token).subscribe((response: any) => {
      this.subvac = [];
      this.vacccinedetail = response;
      for(var i=0;i< response.sub_vaccine.length ; i++){
        this.subvac.push(response.sub_vaccine[i].drug_name)
      }
      this.reschedulevaccine.reset();
  
  });
  }

  card_related(data){
       console.log("data.statusdata.status", data.status);
    this.subvaccines = [];
    this.sub_vaccines = [];
    this.subvacsno = [];
    this.showcheckbox = false;
    // this.dependency = data.dependency
    this.datepick = false;
    this.imagegiven = false;
    this.deferreddate = false;
    this.composite = false;
    this.category = status;
    this.showimageupload = false;

    var datadrug = data.dependency;
    this.dependencyexists = data.dependency
    var mydata = datadrug.split("+");

    if(data.dependency != ''){
      this.dependency = mydata[0] +" In Next "+mydata[1]+" days"
    }else{
      this.dependency  = ""
    }
    this.imageandcomment = data;
    if((data.status == "adminsitrated-1" || data.status == 'Administered')){

      var vacccinedetailsparams = {
        "account_email": this.accountemail,
        "patient_email": this.patientemail,
        "sno": data.sno
      }
  
      this.vacccine_details(this.urlPoints.vacccinedetails , vacccinedetailsparams , this.token)
      
      this.hideall = false;
      this.showdeffer = false;
      this.imagegiven = false;
      this.textselectbrand = false;
      this.openrescheduleddate = false;
    }else if(data.status == "rescheduled"){
      var vacccinedetailsparams = {
        "account_email": this.accountemail,
        "patient_email": this.patientemail,
        "sno": data.sno
      }
  
      this.vacccine_details(this.urlPoints.vacccinedetails , vacccinedetailsparams , this.token)
      this.hideall = false;
      this.showdeffer = true;
    }else{
      this.hideall = true;
      this.showdeffer = false;
    }


    this.imageForm.reset();
    this.imageForm2.reset();
    this.currentvaccine = "";
    this.currentvaccine = data
    this.closesidebar = true; 
    this.duedate = data.due_date
    this.selectedvaccine = data;
    this.drugname = data.drug_name;
    this.brandparams = {
      "account_email": this.accountemail,
      "s_no": data.sno,
      "id":data.id
    }
  this.vaccine_brandlist(this.urlPoints.brandlist , this.brandparams , this.token)

  }

  close_btn(){
       this.closesidebar = !this.closesidebar 
       this.showcheckbox = false;
  }
  
  reset_btn(){
  }

  givendate_btn(){
  }

  duedate_btn(){
  }
 
  add_vaccine(data){
  
      this.brand = true
      this.branddata = data

      this.brand_name = data

      console.log("brandname", data);
      console.log("branddata", this.branddata);
      // var params = {
      //   "patient_email": this.patientemail, 
      //   "account_email": this.accountemail, 
      //   "schedule_id": this.givenid, 
      //   "brand_name": data
      // }
      // console.log("params", params);

      // this.appService.postData(this.urlPoints.addbrandvaccination, params, this.token).subscribe((response: any) => {
      //     console.log("response", response);
      // });

  }

  selectfile(event){
    this.progress_bar = true
    if(event.target.files){
        var reader = new FileReader()
        console.log("reader.result", reader.result);
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) =>{
           this.url = event.target.result;
        }
    }
    const file = (event.target as HTMLInputElement).files[0];
    var uploadFileName = file;
    console.log("uploadFileName", uploadFileName);
    console.log(uploadFileName.name);
    var dummyurl
    this.appService.postimgData(dummyurl, uploadFileName , this.token).subscribe((response: any) => {

      console.log("response 207", response);
      this.progress = Math.round((response.loaded / response.total) * 100);
      if(this.progress == 100){
           this.progress = 100
           console.log(response.body)
           this.progress_bar = false
      }
      console.log("this.progress", this.progress);
      this.uploadedimg = response.body;
    
    });
}


  upload_batch(){

     if(this.uploadedimg  != ""){
         var actualimage = this.uploadedimg.image[0].filename
        var params = {
            "patient_email":this.patientemail,
            "account_email": this.accountemail,
            "schedule_id": this.selectedvaccine.id,
            "image_url": actualimage
        }
        this.appService.postData(this.urlPoints.addvaccinationbrandcode, params, this.token).subscribe((response: any) => {
             this.toastr.success("Batch image Uploaded successfully")
             this.batchimage = [];
          });

     }else{
         this.toastr.error("upload batch image")
     }
  }

}

// notgiven popup

export interface Dialognotgiven {
  deferdata:any
}


@Component({
  selector: 'notgiven',
  templateUrl: 'notgiven.html',
  styleUrls: ['notgiven.scss']
})
export class DialogOverviewExampleDialogaddscanreport {
  constructor(
    public dialogRef: MatDialogRef< DialogOverviewExampleDialogaddscanreport>,
    @Inject(MAT_DIALOG_DATA) public data: Dialognotgiven) {
  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  notgivenyes(data){
    if(data == 'yes'){
      this.dialogRef.close(data);
    }else{
      this.dialogRef.close();
    } 
  }
}



@Component({
  selector: 'printvaccine',
  templateUrl: 'printvaccine.html',
  styleUrls: ['printvaccine.scss']
})
export class DialogOverviewExampleDialogprintvaccine {
  constructor(
    public dialogRef: MatDialogRef< DialogOverviewExampleDialogprintvaccine>,
    @Inject(MAT_DIALOG_DATA) public data: Dialognotgiven) {
  }
  ngOnInit(): void {
  }
  Close() {
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  selectedvac(data){
     console.log("selected vaccine", data);
     this.dialogRef.close(data);
  }
 
}




export interface Dialogdeffer {
  deferdata1:any
}


@Component({
  selector: 'deffer',
  templateUrl: 'deffer.html',
  styleUrls: ['deffer.scss']
})
export class DialogOverviewExampleDialogdeffer {
  constructor(
    public dialogRef: MatDialogRef<  DialogOverviewExampleDialogdeffer>,
    @Inject(MAT_DIALOG_DATA) public data: Dialogdeffer) {
  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  defferyes(data){
    if(data == 'yes'){
      this.dialogRef.close(data);
    }else{
      this.dialogRef.close();
    } 
  }
}

//pending

export interface Dialogpending {
  deferdata2:any
}


@Component({
  selector: 'pending',
  templateUrl: 'pending.html',
  styleUrls: ['pending.scss']
})
export class DialogOverviewExampleDialogpending {
  constructor(
    public dialogRef: MatDialogRef<  DialogOverviewExampleDialogpending>,
    @Inject(MAT_DIALOG_DATA) public data: Dialogpending) {
  }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  pendingyes(data){
    if(data == 'yes'){
      this.dialogRef.close(data);
    }else{
      this.dialogRef.close();
    } 
  }
}