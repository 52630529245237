import { Component, OnInit } from '@angular/core';
import { PrintService } from '../services/print.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss']
})
export class PrintLayoutComponent implements OnInit {
  title = 'angular-print-service';
  routeURL;
  getaccount_email;
  getoverview_Id;
  getdoctor_Id;
  getrespect_path;
  drName;
  drID;
  vitalPrintData;
  mrNum;
  vitDate;
  vaccMsg;
  VaccDate;
  doctorName;
  doctorID;
  authToken;
  accountID;
  apptId;
  nextvisitDate;
  followupDate;
  checkpath;
  billnum;
  patientEmail;
  follicular;
  risk;
  currentdoctID;
  vitalweight;
  vitalheight;
  vitaltemp;
  presprintname;
  presprintgender;
  presprintage;
  getvitalweight;
  getvitalheight;
  getvitaltemp;
  getpresprintname;
  getpresprintgender;
  getpresprintage;
  todayDate: any;
  editvitaltemp;
  

  constructor(private route: ActivatedRoute, private router: Router, public printService: PrintService) {
  
   }

  ngOnInit() {
    this.onPrintInvoice();
  }

  onPrintInvoice() {
    this.routeURL = this.route.queryParams
      .subscribe(params => {
        this.getaccount_email = params['account_email'];
        this.getoverview_Id = params['overview_Id'];
        this.getdoctor_Id = params['doctor_Id'];
        this.mrNum = params['mr_num'];
        this.vitDate = params['vit_date'];
        this.vaccMsg = params['vacc_msg'];
        this.VaccDate = params['vacc_date'];
        this.getrespect_path = params['route_path'];
        this.drName = params['dr_Name'];
        this.drID = params['dr_regNo'];
        this.authToken = params['auth_token'];
        this.apptId = params['appoint_Id'];
        this.followupDate = params['followup_date'];
        this.billnum = params['bill_num'];
        this.patientEmail = params['patient_email'];
        this.follicular = params['faliculr_status'];
        this.risk = params['risk_status'];
        this.vitalweight = params['vital_weight'];
        this.vitalheight = params['vital_height'];
        this.vitaltemp = params['vital_temp'];
        this.presprintname = params['pres_printName'];
        this.presprintgender = params['pres_printGender'];
        this.presprintage = params['pres_printAge'];
        
      });
      this.doctorName = this.drName;
      this.doctorID = this.drID;
      this.currentdoctID = this.getdoctor_Id;
      this.accountID = this.getaccount_email;
      this.nextvisitDate = this.followupDate;
      this.checkpath = this.getrespect_path;
      this.getvitalweight = this.vitalweight;
      this.getvitalheight = this.vitalheight;
      
      this.getvitaltemp = this.vitaltemp;
       if(this.getvitaltemp == 'null'){ 
          this.editvitaltemp = '';
        }else{ 
          this.editvitaltemp = this.getvitaltemp;
        }      
     
      this.getpresprintname = this.presprintname;
      this.getpresprintgender = this.presprintgender;
      this.getpresprintage = this.presprintage;
      
      var date = new Date();
    var today = date.toString().slice(4,15).split(" ");
    this.todayDate = today[1]+'-'+today[0]+'-'+today[2];

      if(this.getrespect_path == 'prescription') {
          this.printService
            .printPresDocument(this.getrespect_path, this.getaccount_email, this.getoverview_Id, this.getdoctor_Id, 
              this.mrNum, this.vitDate,this.vaccMsg, this.VaccDate, this.authToken, this.apptId, this.follicular, this.risk, this.patientEmail);
      }else if(this.getrespect_path == 'cashReceipt') {
        this.printService
        .printReceiptDocument(this.getrespect_path, this.getaccount_email,this.patientEmail,this.billnum, this.authToken, this.currentdoctID);
      }
    }
}
