<div style="display: flex;justify-content: space-between;font-size: 1rem;">
    <p style=color:#0063FF;font-weight:600>Signs</p>

    <p style="cursor:pointer" [class.hide]="!provisional" [class.checked]="provisional" (click)="changeProv()">Provisional <img class="icon" style="margin-left: 0.5rem;" [src]="provisional?'assets/provtrue.svg':'assets/provfalse.svg'"></p>

    <div style="display: flex;">
        <div>
            <div style="display: flex;justify-content: center;">
                <img class="icon" src="assets/keyboard.svg" (click)="openKeyboard()">
            </div>
            <p style="font-size: 0.75rem;">Keyboard</p>
        </div>
        <div style="margin-left: 00.5rem;">
            <div style="display: flex;justify-content: center;">
                <img class="icon"  (mouseup)="mouseup()" (mousedown)="mousedown()" (mouseleave)="mouseup()" [src]="muted?'assets/mute.svg':'assets/unmute.svg'">
            </div>
            <p  style="font-size: 0.75rem;">Voice</p>
        </div>
        <div style="border: 2px solid #7D7D7D;height: 1.6rem;margin-left: 00.5rem;border-radius:5px">
            <input style="border: none;outline: none;width: 10rem;" [(ngModel)]="search" (input)="search1($event)" placeholder="Search"> <i style="margin-right: 0.5rem;" class="fa fa-search" aria-hidden="true"></i>

        </div>

        <button style="margin-left:00.5rem;height:1.6rem;padding-top:0" class="btn btn-primary" (click)="save()">Save</button>

        <i style="margin-left:00.5rem;font-size: 1.5rem;color:#0063FF;" class="fa fa-close" (click)="close()"></i>

    </div>

 
</div>

<div *ngIf="showKB" style="display: flex;flex-wrap: wrap;margin-top:0.5rem">
    <p class="alphabet" *ngFor="let alphabet of alphabets" (click)="keyboard(alphabet)">
        {{alphabet}}</p>
    <p class="cc_item alphabet" (click)="clear()">Clear</p>
</div>

<div style="display: flex;justify-content: space-between;margin-top: 0.5rem;">
    <p style="color: #7D7D7D;">Added Signs</p>

    <img style="height: 1.5rem;
    width: 1.5rem;cursor: pointer;" (click)="new_cc()" src="assets/add.png">
</div>

<div>
    
    <div [ngStyle]="{'max-height':data.toggle_alignment ? '11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;">
        <div #cc_item style="border-radius: 2px ;color: #00AF69 !important;border:1px solid #00AF69 !important;"
        class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
        *ngFor="let cc of cc_selected;let indexOfelement=index;let i = index">
        {{cc.name}}  <i style="color: #00AF69 ;" class="fa fa-close" (click)="remove(i)"></i>
    </div>

    </div>
</div>

<div style="margin-bottom: 1rem;" *ngIf="search==''&&((doc_id!=4&&dmail=='rx4@rxcarenet.org')||(dmail!='rx389462@rxcarenet.org' && dmail!='rx483098@rxcarenet.org'))">
    <p style="color: #7D7D7D;">Frequently Used</p>

    <div [ngStyle]="{'max-height':data.toggle_alignment ? '11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;">
        <div #cc_item 
        class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
        *ngFor="let cc of cc_show;let indexOfelement=index;let i = index" (click)="addCC(i)">
        {{cc.name}}
    </div>
    </div>

    <p *ngIf="cc_show.length==0">No frequently used items found</p>    

</div>


<div style="margin-bottom: 1rem;" *ngIf="search!=''">
    <p style="color: #7D7D7D;">Search Results</p>

    <div [ngStyle]="{'max-height':data.toggle_alignment ? '11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;" infiniteScroll
    [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    [infiniteScrollDisabled]="isFullListDisplayed"
    [scrollWindow]="false">
        <div #cc_item
        class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
        *ngFor="let cc of cc_show;let indexOfelement=index;let i = index"  (click)="addCC(i)">
        {{cc.name}}
    </div>
    </div>

    <p *ngIf="cc_show.length==0">No results found</p>    

</div>

<hr style="color: #7D7D7D;margin-bottom: 1rem;" *ngIf="(doc_id!=4&&dmail=='rx4@rxcarenet.org')&&(dmail!='rx389462@rxcarenet.org')">

<p style="color: #7D7D7D;">All</p>


<div style="margin-top: 00.5rem;">
    <div [ngStyle]="{'max-height':data.toggle_alignment ? '11rem':'7.5rem'}" style="display: flex;flex-wrap: wrap;overflow: auto;" infiniteScroll
    [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="onScroll2()"
    [infiniteScrollDisabled]="isFullListDisplayed"
    [scrollWindow]="false">
        <div #cc_item
        class="cc_item chip chip-md white-text  example z-depth-2 mr-1 success-color"
        *ngFor="let cc of cc_all;let indexOfelement=index;let i = index"  (click)="addCC1(i)">
        <span *ngIf="cc.name!='' ">{{cc.name}}</span>
    </div>
    </div>

    <p *ngIf="cc_all.length==0">No results found</p>    

</div>

