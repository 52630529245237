import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { AppService } from 'src/app/app.service';
//import { SharedService } from 'src/app/services/shared.service';
import { AppConfig } from 'src/app/app.config';
//import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reportdetails',
  templateUrl: './reportDetails.component.html',
  styleUrls: ['./reportDetails.component.scss'],
  //providers: [NgbCarouselConfig]
})
export class ReportsComponent implements OnInit {

  @Input() headerTitle: string;
  

  constructor(private router: Router, private route: ActivatedRoute,
    private appService: AppService,private toastr: ToastrService, private formBuilder: FormBuilder) {
      
    
    }

  ngOnInit(): void {
    this.sublefttabs(0);
  }
  
  
  sublefttabs(tabindex){
   if(tabindex > 18){
    for (var i = 0; i < 16; i++) {
    const el =  document.getElementsByClassName('leftsubtabselect')[i] as HTMLElement;
          
          el.style.border = "0px solid #c4d5ef"; 
          el.style.borderBottom = "1px solid rgba(0,0,0,.125)";
          el.style.color = "#000000";
          el.style.fontWeight = "normal";
          el.style.boxShadow = "none";
          el.style.backgroundImage = "none";
    }
    document.getElementById('id_'+tabindex).style.borderBottom  = "2px solid #00A0E3";
    document.getElementById('id_'+tabindex).style.color  = "#00A0E3";
    document.getElementById('id_'+tabindex).style.fontWeight  = "bold";
    document.getElementById('id_'+tabindex).style.boxShadow  = "4px 2px #cbdbf3";
    document.getElementById('id_'+tabindex).style.backgroundImage  = "linear-gradient(#cbdbf3, #fff)";
  }
     
}
  
  

}
