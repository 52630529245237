<div id="pause" *ngIf="isPrinting" class="d-flex align-items-center justify-content-center">
    <img src="assets/printing.gif">
</div>

<div class="conTaniner">
    <div class="row titleContainer">
        <div class="col-sm-5 col-md-5 col-lg-5 containerTop">
            <div><span style="cursor:pointer" (click)="onNoClick();"><i class="fa fa-arrow-left" aria-hidden="true" style="font-size: 18px;"></i> </span> </div>
        </div>
        <div class="col-sm-7 col-md-7 col-lg-7 containerTop">
            <p class="headText">Consultation History</p>
        </div>
    </div>


    <div  *ngIf="!defPage">
        <div style="display: flex;margin-top: 2.5rem;">
            <div>
                <img style="height: 1rem;width: 1rem;margin-right: 0.5rem;cursor: pointer;"  (click)="picker.open()"  src="assets/metro-calendar.svg">
                Filter Date : <span *ngIf="filterDate">{{filterDate  | date:'dd-MMM-yyyy'}}</span>
                    <span *ngIf="!filterDate">All</span>
                        <mat-form-field style="width:0px !important;visibility:hidden;">
                        <input matInput [matDatepicker]="picker" [(ngModel)]="filterDate" (dateChange)="filter()" [max]="maxDate">
                        <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
            </div>

            <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 1rem;">
                <i style="vertical-align: middle;cursor: pointer;font-size: 1.5rem;line-height: 1rem;margin-top: 0.5rem;" class="fa fa-times" (click)="removeDate()" aria-hidden="true"></i>
            </div>

            <div style="margin-left: 1rem;display: flex;justify-content: center;flex-direction: column;margin-top: 0.75rem;">
            
                <div style="display: flex;">
                    <p style="margin: 0;">Filter Diagnosis : </p>
                    <input style="margin-left : 1rem" (input)="filter()" [(ngModel)]="filterDiagnosis">
                    <i style="vertical-align: middle;cursor: pointer;font-size: 1.5rem;line-height: 1rem;margin-top: 0.25rem;margin-left: 00.5rem;" class="fa fa-times" (click)="removeText()" aria-hidden="true"></i>

                </div>
            

            </div>

            <div style="display : flex;margin-left : auto;margin-right: 1rem;">
                
                <button *ngIf="showType == 1" (click)="setShowType(2)" style="color: #FFF;" class="btn btn-primary">
                    Past Medical Records
                </button>

                <button *ngIf="showType == 2" (click)="setShowType(1)" style="color: #FFF;" class="btn btn-primary">
                    Back
                </button>
            </div>

            <div>
                <img style="cursor:pointer;width: 40px;" title="External Details" src="assets/external-link.png" (click)="getExternalConhistory()">
            </div>
        </div>

        <div *ngIf="showType == 1&&!defPage">
            <div *ngIf="!detailViewOpenState" class="row" style="margin-left:6px;margin-top: 10px;width:100%">
                <div *ngIf="visitHistorydetails.length == 0" style="width:100%;margin-top:10%;text-align: center;"><div>No Consultation Found</div></div>
                <table style="width:98%"  *ngIf="visitHistorydetails.length != 0">
                        <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                            <tr style="height: 30px;">
                                <th class="obsttableheadcolor" style="width: 2%;">S.No
                                </th>
                                <th class="obsttableheadcolor" style="width: 5%;">Date
                                </th>
                                <th class="obsttableheadcolor" style="width: 10%;">Complaint
                                </th>
                                <th class="obsttableheadcolor" style="width: 10%;">Diagnosis</th>
                                <th class="obsttableheadcolor" style="width: 10%;">Medicine Given</th>
                                <th class="obsttableheadcolor" style="width: 3%;">Detailed View</th>

                                <th class="obsttableheadcolor" style="width: 3%;">Share</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visithistoryList of visitHistorydetails;let i=index" style="border: 2px dotted #e3edf8;text-align: center;">
                                <td style="width: 2%;">{{i+1}}
                                </td>
                                <td style="width: 5%;">{{visithistoryList.date | date:'dd-MMM-yyyy'}}
                                </td>
                                <td style="width: 10%;text-align: left;padding:10px;line-height: 25px;">
                                    <div *ngFor="let complaint of visithistoryList.complaint;let j=index"> 
                                    <span>{{j+1}}.</span> <span> {{complaint.complaint_category | titlecase}} </span>
                                    </div>
                                </td>
                                <td style="width: 10%;padding:10px;line-height: 25px;">{{visithistoryList.diagnosis | titlecase}}</td>
                                <td style="width: 10%;text-align: left;padding:10px;line-height: 25px;">
                                    <div *ngFor="let presdetails of visithistoryList.drugs;let j=index"> 
                                    <span>{{j+1}}.</span> 
                                    <span>{{presdetails.formulation | titlecase}} <span *ngIf="presdetails.generic_name!=''"> - {{presdetails.generic_name | titlecase}}</span> <span *ngIf="presdetails.brand_name!='' && presdetails.brand_name!=null"> ({{presdetails.brand_name | titlecase}})</span> <span *ngIf="presdetails.strength!='' && presdetails.strength!=null">({{presdetails.strength}})</span></span>
                                    </div>
                                </td>
                                <td style="width: 3%;"><img  src="assets/eye.svg" style="width:25px;cursor: pointer;" (click)="consultHistory(visithistoryList.id,visithistoryList.appointment_id);opendetailedView()" /></td>
                                <td>
                                    <div style="display: flex;">
                                        <img style="width: 1.5rem;cursor: pointer;" (click)="printPres2(visithistoryList.id,1)" src="assets/sms_icon.png">
                                        <img style="width: 1.5rem;cursor: pointer;margin-left: 00.5rem;" (click)="printPres2(visithistoryList.id,0)" src="assets/mail_icon.png">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                </table>
            </div>
        
            <div *ngIf="detailViewOpenState" style="margin-left:6px;margin-top: 50px;width:99%">
                
                <div class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6"></div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                        <button style="border:none;background: none;color:#1A73FF;float: right;" (click)="goback()">Back</button>
        
                        <button style="border:none;background: none;float: right;"><i class="fa fa-print" (click)="printPres1(preview_id, app_id)" aria-hidden="true" style="font-size: 24px;"></i></button> 
                
                        <!-- <button style="border:none;background: none;float: right;"
                        [useExistingCss]="false"
                        [printStyle]="{'.noprint' : {'font-size': '14px;'}}"
                        printSectionId="print-section" ngxPrint><i class="fa fa-print" aria-hidden="true" style="font-size: 24px;"></i></button>  -->
                    </div>
                </div>

                <!-- <div *ngFor="let p of printArr">
                    <div class="printPres-iframeDiv" *ngIf="callprintprespopup==true">
                        <iframe style="width: 100%;height: 100%;"
                            [src]="currentProjectUrl">
                        </iframe>
                    </div>
                </div> -->
        
                <div id="print-section" class="noprint">
                    <div class="Rtable Rtable--4cols" style="display: flex;flex-wrap: wrap;margin: 0 0 0 0;padding: 0;">  
                        <div class="Rtable-cell" style=" width: 25%;"><span class="fontWeight">Name :&nbsp;&nbsp;</span><span>{{patient_name | titlecase}}</span></div>
                        <div class="Rtable-cell" style=" width: 25%;"> <span class="fontWeight">MR No :&nbsp;&nbsp;</span><span>{{mrNo}}</span></div>
                        <div class="Rtable-cell" style=" width: 25%;"> <span class="fontWeight">DOB :&nbsp;&nbsp;</span><span>{{Dob | date: 'dd-MM-yyyy'}}</span></div>
                        <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">Date :&nbsp;&nbsp;</span><span>{{todayDate}}</span></span></div>
                    </div>
                    
                    <div class="Rtable Rtable--4cols" style="display: flex;flex-wrap: wrap;margin: 0 0 0 0;padding: 0;">
                        <div class="Rtable-cell" style=" width: 25%;"> <span class="fontWeight">Sex :&nbsp;&nbsp;</span><span>{{sex | titlecase}}</span></div>
                        <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">Age :&nbsp;&nbsp;</span><span>{{age}}</span></span></div>
                        <div class="Rtable-cell" style=" width: 25%;"> <span class="fontWeight">LMP :&nbsp;&nbsp;</span><span>{{lmp}}</span></div>
                        <div class="Rtable-cell" style=" width: 25%;"> <span><span class="fontWeight">EDD :&nbsp;&nbsp;</span><span>{{edd}}</span></span></div>
                    </div>
                    
                    <div style="margin-left: 0px !important; margin-right: 0px !important;margin-top: 5px;border-top: 2px solid #CBDBF3;"></div>
                    
                    <div class="row" style="margin-top: 20px !important;"  *ngIf="riskdata!=''">
                        <div class="col-md-12">
                            <label id="prescription" style="font-weight: bold;">Pregnancy Risk : </label> <span style="margin-left: 10px">{{riskTo | titlecase}}</span>
                        </div>
                    </div> 
                    
                        <div class="row" style="margin-top: 20px !important;" *ngIf="vital != 0">
                            <div class="col-md-12">
                                <label id="prescription" style="font-weight: bold;">Vitals : </label>
                                    <div class="Rtable Rtable--4cols" style="display: flex;flex-wrap: wrap;margin: 0 0 0 0;padding: 0;">
                                        <div class="Rtable-cell" style=" width: 25%;"> <span>Weight (kg) :&nbsp;&nbsp;</span><span class="fontWeight">{{weigth}}</span></div>
                                        <div class="Rtable-cell" style=" width: 25%;"> <span><span>BP :&nbsp;&nbsp;</span><span class="fontWeight">{{Bp}}</span></span></div>
                                        <div class="Rtable-cell" style=" width: 25%;"> <span>&nbsp;&nbsp;</span></div>
                                        <div class="Rtable-cell" style=" width: 25%;"> <span></span></div>
                                    </div>
                            </div>
                        </div>
                        <br>
                    
                        <div style="display: flex;">
                            <div style="width: 25%;" *ngIf="complaint != null">
                                <div> <span class="fontWeight">Chief Complaints :</span> </div>
                                <div>
                                    <table *ngFor="let compissues of complaintissues">
                                        <thead style="font-weight: normal !important;"><td>{{compissues.complaint_category | titlecase}}</td></thead> 
                                        <tbody *ngFor="let val in compissues.details">
                                            <tr *ngFor="let val1 in val.answers.split('^~^')">
                                                <td style="margin-top:-15px;">{{val1}}</td>
                                            </tr>       
                                        </tbody>                            
                                    </table>
                                </div>
                            </div>
                            <div style="width: 25%;" *ngIf="findings!='' && findings!=undefined">
                                <div> <span class="fontWeight">Findings :</span> </div>
                                <div><span>{{findings}}</span></div>
                            </div>
                            <div style="width: 25%;" *ngIf="diagnosis!='' && diagnosis!=undefined">
                                <div> <span class="fontWeight">Provisional Diagnosis :</span> </div>
                                <div> <span>{{diagnosis}}</span> </div>
                            </div>
                            <div style="width: 25%;" *ngIf="advice!=''&&advice!=undefined">
                                <div> <span class="fontWeight">Advice :</span> </div>
                                <div> <span>{{advice}}</span> </div>
                            </div>
                        </div>
                        
                        <div style="margin-left: 0px !important; margin-right: 0px !important;margin-top: 5px;padding-top: 3px;border-top: 2px solid #CBDBF3;"></div>
                        
                        <div class="row" style="margin-top: 0px;">
                            <div class="col-md-12" *ngIf="prescrDetails != null">
                                <br>
                                <label id="prescription" style="font-weight: bold;">Drug Prescription : </label>
                                    <table *ngIf="!(dmail ==  'rx122340@rxcarenet.org' && doc_id==1)" class="table" id="table_width">
                                        <thead style="background-color: #E5F0FF;text-align: center;text-align: center;
                                        font-family: 'Poppins';
                                        height: 30px;
                                        font-size: 14px;" class="tableheader">
                                            <tr>
                                                <th style="border: 2px solid #CBDBF3;">S.No.</th>
                                                <th style="border: 2px solid #CBDBF3;">Formulation - Drug Generic (Composition Name) (Strength)</th>
                                                <th style="border: 2px solid #CBDBF3;">Route</th>
                                                <th style="border: 2px solid #CBDBF3;">Dosage</th>
                                                <th style="border: 2px solid #CBDBF3;">Frequency</th>
                                                <th style="border: 2px solid #CBDBF3;">Duration</th>
                                                <th style="border: 2px solid #CBDBF3;">Total</th>
                                                <th style="border: 2px solid #CBDBF3;">Time</th>
                                                <th style="border: 2px solid #CBDBF3;">Special Instructions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let presdetails of prescrDetails; let i = index">
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{i+1}} </td> 
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{presdetails.formulation | titlecase}} <span *ngIf="presdetails.generic!=''"> - {{presdetails.generic | titlecase}}</span> <span *ngIf="presdetails.composition_name!='' && presdetails.composition_name!=null"> ({{presdetails.composition_name | titlecase}})</span> <span *ngIf="presdetails.strength!='' && presdetails.strength!=null">({{presdetails.strength}})</span></td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{presdetails.route}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;"><span>{{presdetails.dose}}</span> {{presdetails.dose_option}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{presdetails.frequency}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{presdetails.duration}} {{presdetails.duration_type}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{presdetails.quantity}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{presdetails.when}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: left;">{{presdetails.special_instruction}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
        
                                    <!-- {{dmail}}{{doc_id}} -->
        
                                    <table *ngIf="(dmail ==  'rx122340@rxcarenet.org' && doc_id==1)" class="table" id="table_width">
                                        <thead style="background-color: #E5F0FF;text-align: center;text-align: center;
                                        font-family: 'Poppins';
                                        height: 30px;
                                        font-size: 14px;" class="tableheader">
                                            <tr>
                                            
        
                                                <th style="border: 2px solid #CBDBF3;">
                                                    <p>S. No</p>
                                                </th>
                                                <th style="border: 2px solid #CBDBF3;">
                                                    <p>Drug and Strength</p>
                                                </th>
                                                <th style="border: 2px solid #CBDBF3;" *ngIf="!durationAlone">
                                                    <p>Dosage, Frequency & Duration</p>
                                                </th>
                                                <th style="border: 2px solid #CBDBF3;" *ngIf="durationAlone">
                                                    <p>Dosage & Frequency</p>
                                                </th>
                                                <th style="border: 2px solid #CBDBF3;" *ngIf="durationAlone">
                                                    <p>Duration</p>
                                                </th>
                                                <th style="border: 2px solid #CBDBF3;" *ngIf="displayTotal&&dmail!='rx122340@rxcarenet.org'">
                                                    <p>Total</p>
                                                </th>
                                                <th style="border: 2px solid #CBDBF3;">
                                                    <p>Time</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pres_s of prescrDetails; let i = index">
        
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">
                                                    <p>{{i+1}}</p>
                                                </td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">
        
                                                    <!-- {{drugShowType}} -->
        
        
                                                    <p *ngIf="drugShowType==1">{{pres_s.generic}} </p>
        
                                                    <p *ngIf="drugShowType==2">{{pres_s.composition}} </p>
        
                                                    <p *ngIf="drugShowType==3">{{pres_s.generic}} - {{pres_s.composition}} 
                                                    </p>
                                                </td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;" *ngIf="!durationAlone">
                                                    <p>
                                                        {{pres_s.frequency}} ,
                                                        {{pres_s.duration}} {{pres_s.duration_type}}
                                                    </p>
                                                </td>
        
                                                <td style="border: 2px solid #CBDBF3; text-align: center;" *ngIf="durationAlone">
                                                    <p>
                                                        {{pres_s.frequency}}
                                                    </p>
                                                </td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;" *ngIf="durationAlone">
                                                    <p>{{pres_s.duration}} {{pres_s.duration_type}}
                                                    </p>
                                                </td>
        
                                                <td style="border: 2px solid #CBDBF3; text-align: center;" *ngIf="displayTotal&&dmail!='rx122340@rxcarenet.org'">
                                                    <p>{{pres_s.quantity}}</p>
                                                </td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;" *ngIf="!fullFreq">
                                                    <p *ngIf="pres_s.when=='After Food'">AF</p>
                                                    <p *ngIf="pres_s.when=='Before Food'">BF</p>
                                                    <p *ngIf="pres_s.when=='With Food'">WF</p>
                                                    <p *ngIf="pres_s.when=='SOS'">SOS</p>
                                                    <p *ngIf="pres_s.when=='Other'">Other</p>
                                                    <p *ngIf="pres_s.when=='Not Related to Food'">NRF</p>
                                                    <p *ngIf="pres_s.when.startsWith('Time')">{{pres_s.when}}</p>
        
                                                    <p *ngIf="pres_s.when=='AF'">AF</p>
                                                    <p *ngIf="pres_s.when=='BF'">BF</p>
                                                    <p *ngIf="pres_s.when=='WF'">WF</p>
                                                    <p *ngIf="pres_s.when=='NRF'">NRF</p>
        
                                                </td>
        
                                                <td style="border: 2px solid #CBDBF3; text-align: center;" *ngIf="fullFreq">
                                                    <p *ngIf="pres_s.when=='After Food'">After Food</p>
                                                    <p *ngIf="pres_s.when=='Before Food'">Before Food</p>
                                                    <p *ngIf="pres_s.when=='With Food'">With Food</p>
                                                    <p *ngIf="pres_s.when=='SOS'">SOS</p>
                                                    <p *ngIf="pres_s.when=='Other'">Other</p>
                                                    <p *ngIf="pres_s.when=='Not Related to Food'">Not Related to
                                                        Food</p>
                                                    <p *ngIf="pres_s.when.startsWith('Time')">{{pres_s.when}}</p>
        
                                                    <p *ngIf="pres_s.when=='AF'">AF</p>
                                                    <p *ngIf="pres_s.when=='BF'">BF</p>
                                                    <p *ngIf="pres_s.when=='WF'">WF</p>
                                                    <p *ngIf="pres_s.when=='NRF'">NRF</p>
                                                </td>
                                            
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12" style="margin-left: 15px !important; margin-right: 0px !important;">
                                <div class="row">
                                    
                                    <div class="pull-right" *ngIf="printfollowupDate !='' || printfollowupDate != null">
                                        <label><span style="font-weight: bold;">Follow Up :</span> &nbsp;&nbsp;<span>{{printfollowupDate}}</span></label>
                                    </div>
                    
                                    <div class="pull-right" *ngIf="nextscanDate !=''">
                                        <label><span style="font-weight: bold;">Next Scan Date :</span> &nbsp;&nbsp;<span>{{nextscanDate}}</span></label>
                                    </div>
                                    
                                </div>
                            </div>    
                        </div>
                        
                        <div class="row">
                                <div class="col-md-12" style="margin-left: 12px !important; margin-right: 0px !important;">
                                    <div class="row">
                                        <div *ngIf="testadvised!=undefined && testadvised!=null && testadvised!=0">
                                            <div>
                                                <label id="prescription" style="font-weight:bold">Test Advised :</label><br>
                                                <span *ngFor="let test of testadvised; let i = index">{{i+1}}. {{test}}<br></span>
                                                <span *ngIf="testadvised=='' || testadvised==null || testadvised==0">No Tests</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="row" style="margin-top: 0px;" *ngIf="currentPrec!=''">
                            <div class="col-md-12">
                                <br>
                                <label id="prescription" style="font-weight: bold;">Current Pregnancy : </label>
                                    <table class="table" id="table_width">
                                        <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                                            <tr>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">Date</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">BP</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">P/A</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let currenpreq of currentPrec; let i = index">
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{currenpreq.date}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{currenpreq.bp}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{currenpreq.pa}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: left">{{currenpreq.remarks}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 0px;" *ngIf="follicleStudy!=''">
                            <div class="col-md-12">
                                <br>
                                <label id="prescription" style="font-weight: bold;">Follicle Studies: </label>
                                    <table class="table" id="table_width">
                                        <thead style="background-color: #E5F0FF;text-align: center;" class="tableheader">
                                            <tr>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">Date</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">Day</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">RT Ovary</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">LT Ovary</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">Endometrium</th>
                                                <th style="border: 2px solid #CBDBF3; text-align: center;">comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let folliclestdy of follicleStudy; let i = index">
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{folliclestdy.date}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{folliclestdy.day}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{folliclestdy.rt_ovary}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{folliclestdy.rt_ovary}} </td>
                                                <td style="border: 2px solid #CBDBF3; text-align: center;">{{folliclestdy.endometrium}}</td>
                                                <td style="border: 2px solid #CBDBF3; text-align: left">{{folliclestdy.comments}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </div>
                </div>
            </div>
        </div>

        <div *ngIf="showType == 2&&!defPage">
            <div class="row">
                <div style="width:99%;margin-top: 15px;" class="col-sm-3 col-md-3 col-lg-3" *ngFor="let complaintdetails of complaintData;let i=index">
                <div *ngIf="complaintdetails.compliant_img?.length>0">
                    <!-- <div style="font-weight: 800;"><span>{{i+1}})</span> <span style="margin-left: 10px;">{{complaintdetails.date | date: "dd-MMM-yyyy"}}</span></div>
                    <hr class="hrStyle"> -->
                    <div  style="margin-left: 20px;">
                        <div  style="margin-top: 15px;" *ngFor="let fileName of complaintdetails.compliant_img;let j=index">
                        <div style="border:2px solid #c8e2ff;padding: 10px;box-shadow: 4px 5px 5px lightgrey;;-webkit-box-shadow: 4px 5px 5px lightgrey;">
                            <div>
                            <img *ngIf="fileName.split('.')[1] == 'png'" src="assets/jpg-file-format.svg" style="width:35px;"  />
                            <img *ngIf="fileName.split('.')[1] == 'pdf'" src="assets/pdf-file-format.svg" style="width:35px;"  />File Name : {{complaintdetails.file_name}}<br>
                            <span style="margin-left: 35px;">Prescription Date : {{complaintdetails.prescription_date | date: "dd-MMM-yyyy"}}</span>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <img  src="assets/eye.svg" title="View" style="float:right;width:20px;cursor: pointer;" (click)="showcompliantData(fileName)"/>
                                </div>
                            </div>
                        </div> 
                        </div>
                    </div>
                </div>

                
                    
                </div>

                <div style="width: 100%;" *ngIf="complaintData.length==0">
                    <p style="text-align: center;margin-top: 10%;">No records found</p>
                </div>
            </div>
        </div>

    </div>


    <div *ngIf="defPage" style="display: flex;margin-top: 5rem;">
        <table style="width: 100%;" *ngIf="conHistorydata.length!=0">
            <tr style="border: 2px solid;">
                <th>S.No</th>
        
                <th>Date</th>
        
                <th>Patient Number</th>
        
                <th>Patient Name</th>
        
                <th>Drug Name</th>
        
                <th>Drug Type</th>

                <th>Dosage & Unit</th>

                <th>Duration & Unit</th>

                <th>Moring</th>

                <th>Afternoon</th>

                <th>Night</th>

                <th>After Food</th>

                <th>Before Food</th>
            </tr>

            <tr style="border: 0.5px solid;" *ngFor="let c of conHistorydata;let i = index">
                <td>{{i+1}}</td>
                <td>{{c.Date | date:'dd MMM yyyy'}}</td>
                <td>{{c.Patient_Number}}</td>
                <td>{{c.Patient_Name}}</td>
                <td>{{c.Drug_Name}}</td>
                <td>{{c.Drug_Type}}</td>
                <td>{{c.Dosage}} {{c.Dosage_Unit}}</td>
                <td>{{c.Duration}} {{c.Duration_Unit}}</td>
                <td>{{c.Morning}}</td>
                <td>{{c.Afternoon}}</td>
                <td>{{c.Night}}</td>
                <td>{{c.After_Food}}</td>
                <td>{{c.Before_Food}}</td>
            </tr>
        </table>
        <div *ngIf="conHistorydata.length == 0" style="width:100%;margin-top:10%;text-align: center;"><div>No Consultation Found</div></div>
    </div>
</div>


